import { Component } from "react";
import Lottie from "react-lottie";
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer'
import "../css/AboutScreenStyles.css";
import Marker from "../lotties/Marker.json";
import "primeflex/primeflex.css";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Marker,
  rendererSettings: {
    // preserveAspectRatio: "xMidYMid slice",
  },
};

export default class ErrorScreen extends Component {
  render() {
    const styles = {
      backgroundColor: '#282b30',
      color: '#d9e3ea',
      textAlign:'center',
      fontSize:'15px',
      marginTop:'-20vh',
      wordWrap: 'break-word',
      width:'90%'
    };


    return (
      <>
       <Navbar bgColor="#282b30"/>
        <div style={{ height:'100vh', backgroundColor:"#282b30", display:'flex', alignItems:'center', justifyContent:'center' }}>
          
          <div  style={styles}>
            <h1>Oops! 404 Error</h1>
            <div>We couldn't find the page you were looking for.</div>
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center'}}>
              <Lottie options={defaultOptions}  height={350} width={350} />
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
