// @ts-nocheck
import { useHistory } from "react-router-dom";

import Home from "../../lotties/Home.json";

import { useAuth } from "../../contexts/AuthContext";

import { ROLES } from "../../constants/Roles";

import DashboardScreen from "../Dashboard/DashboardScreen";
import ManageOrganizationScreen from "../ManageOrganizationScreen/ManageOrganizationScreen";
const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: Home,
  rendererSettings: {
    // preserveAspectRatio: "xMidYMid slice",
  },
};
const styles = {
  footer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bottom: 0,
    left: 0,
    width: "100%",
  },
};
export default function DashboardSwitch() {
  const history = useHistory();
  const { logout, user, campaigns, premiumStatus,activeSubscription } = useAuth();
  console.log('DashboardScreen:user',JSON.stringify(user))
  const manageOrgDash = (user.role === ROLES.ADMIN || user.role === ROLES.OWNER) && user.orgId;
  return (
   <>
   {manageOrgDash ? <ManageOrganizationScreen/>:<DashboardScreen/>}
   </>
  );
}
