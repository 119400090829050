import {useRef} from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import LandingScreen from "./screens/Landing/LandingScreen";
import SignInScreen from "./screens/Signin/SignInScreen";
import SignUpScreen from "./screens/SignUpScreen";
import { AuthProvider } from "./contexts/AuthContext";
import AccountRecovery from "./screens/AccountRecovery";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import UninstallScreen from "./screens/UninstallScreen";
import DashboardScreen from "./screens/Dashboard/DashboardScreen";
import NewQRForm from "./screens/NewQR/NewQRFormScreen";
import ManageCampaignScreen from "./screens/ManageCampaign/ManageCampaignScreen";
import ManageOrganizationScreen from "./screens/ManageOrganizationScreen/ManageOrganizationScreen";

import ErrorScreen from "./screens/ErrorScreen"
import TermsOfServiceScreen from './screens/TermsOfServiceScreen'
import SignUpPlans from './screens/SignUpMarketerAccountScreen'

import ActionUrlScreen from './screens/ActionUrl/ActionUrlScreen';
import { Toast } from "primereact/toast";
import ToastUtil from "./components/ToastUtil/ToastUtil";
import LoadingUtil from "./components/LoadingDialog/LoadingUtil";
import LoadingDialog from "./components/LoadingDialog/LoadingDialog";
import DialogModal from "./components/DialogModal/DialogModal";
import DialogModalUtil from "./components/DialogModal/DialogModalUtil";
import { GoogleOAuthProvider } from '@react-oauth/google';
import DashboardSwitch from "./screens/DashboardSwitch/DashboardSwitch";

function App() {
  const toast = useRef(null);
  const loading = useRef(null)
  const dialog = useRef(null)
  ToastUtil.toast = toast;
  LoadingUtil.loading = loading;
  DialogModalUtil.dialog = dialog;
  
  return (
    <div className="App">
      <GoogleOAuthProvider clientId="655418670949-ghbel7ofa2nhbspnm7ptr8vlili0ubae.apps.googleusercontent.com">

      <Toast ref={toast} position="top-right" />
      <LoadingDialog ref={loading} />
      <DialogModal ref={dialog} />
      <Router>
        <AuthProvider>
          <Switch>  
            <Route exact path="/" component={LandingScreen} />
            <Route path="/signup" component={SignUpScreen} />
            <Route path="/signin" component={SignInScreen} />
            <Route exact path="/plans" component={SignUpPlans} />

            <Route path="/account-recovery" component={AccountRecovery} />
            <Route path="/uninstall/:uids?" component={UninstallScreen} />
            <Route path="/terms" component={TermsOfServiceScreen} />
            <Route exact path="/action-url" component={ActionUrlScreen} />
            <PrivateRoute exact path="/newCampaign" component={NewQRForm} />
            <PrivateRoute exact path="/manageCampaign" component={ManageCampaignScreen} />
            <PrivateRoute exact path="/org" component={ManageOrganizationScreen} />
            <PrivateRoute exact path = "/qrs" component ={DashboardScreen} />
            <PrivateRoute exact path = "/dashboard" component ={DashboardSwitch} />
            <Route component={ErrorScreen} />
          </Switch>
          <link href="css/styles.css" rel="stylesheet" />
          {/* <!-- Scripts --> */}
          <script src="js/jquery.min.js"></script>
          <script src="js/popper.min.js"></script>
          <script src="js/bootstrap.min.js"></script>
          <script src="js/jquery.easing.min.js"></script>
          <script src="js/swiper.min.js"></script>
          <script src="js/jquery.magnific-popup.js"></script>
          <script src="js/validator.min.js"></script>
          <script src="js/scripts.js"></script>
          <script src="https://cdn.jsdelivr.net/npm/chart.js@2.8.0"></script>
        </AuthProvider>
      </Router>
      </GoogleOAuthProvider>
    </div>
  );
}
export default App;