import { useState, useRef } from 'react';
import Navbar from "../../components/Nav/Navbar";
import MarketingCard from './MarketingCard';
import MembersCard from './MembersCard';
import BillingCard from './BillingCard';
import Lottie from "react-lottie";
import { useHistory,useLocation } from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";
import PanelSingleCard from "../../components/Panels/PanelSingleCard";
import { Card } from "primereact/card";
import Home from "../../lotties/Home.json";
import {screenStyles} from '../ScreenStyles'
import 'primeicons/primeicons.css';


const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: Home,
  rendererSettings: {
    // preserveAspectRatio: "xMidYMid slice",
  },
};


const styles = {
  title: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "5px",
    marginTop:-15,
    marginRight:'5%',
    fontSize: "1.5rem",
    fontWeight: "700"
  },
  tag : {
    borderStyle: 'solid',
    borderWidth: '1.5px',
    borderRadius: '5px',
    borderColor: '#dadada',
    padding: '2.5px 7.5px',
    display: 'inline-block',
    '&:hover' : {
      backgroundColor: '#efefef'
    }
  },
  footer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bottom: 0,
    left: 0,
    width: "100%",
    paddingTop:'50px'
  },
}



export default function ManageOrganizationScreen() {
  const {org, setOrg, user} = useAuth();
  const [googleId, setGoogleId] = useState(org.googleId)
  const [facebookId, setFacebookId] = useState(org.facebookId)
  const [members, setMembers] = useState(org.members)
  const location = useLocation();

  console.log("ManageOrgScreen:org", org)

  const history = useHistory();
  return (
    <div style={{ flex:1,paddingTop: "80px",backgroundColor:"#EFEFEF", width: '100%',
    height: '100%', }}>
      <Navbar
        leftClick={() => {
          console.log("hello");
          history.push("/dashboard")
        }}
        pageTitle={""}
      />

      <div style = {screenStyles.body}>
        <PanelSingleCard component={MembersCard} panelTitle = {"Team"} org={org} updateOrg={setOrg}/>
        <PanelSingleCard  component={MarketingCard} panelTitle = {"Marketing"} googleId={googleId} facebookId={facebookId}/>
        {user.billingAccess ? <PanelSingleCard  component={BillingCard} panelTitle = {"Billing"} bill={user.billing.billingString} members={user.billing.quantity} nextBillingDate={user.billing.periodEndString}/>:null}
      </div>

      <div style={styles.footer}>
        <div style={{ maxWidth: "75%" }}>
          <Lottie options={defaultOptions} />
        </div>
      </div>
    
    </div>
  );
}
