import React from "react";
import { useAuth } from "../../contexts/AuthContext";
import { style } from "./CardStyles";
import { InputText } from "primereact/inputtext";

export default function TrackingSection(props) {
    const { activeSubscription, user } = useAuth();

  const hide = user.orgId || !activeSubscription;

  if (hide) {
    return null;
  } else {
    return (
      <>
        <div style={{ marginTop: "50px", marginBottom: "20px" }}>
          <div style={{ display: "inline-block" }}>
            <div
              style={{
                fontSize: "30px",
                fontWeight: "700",
                lineHeight: 1,
                fontWeight: "700",
              }}
            >
              Tracking
            </div>
            <div
              style={{ fontSize: "12px", color: "#9e9b9b", marginLeft: "5px" }}
            >
              (optional)
            </div>
          </div>
        </div>
        <h4>Google Tag Id</h4>
        <InputText
          value={props.state.googleId}
          style={style.cardInput}
          onChange={(e) => {
            props.update({ googleId: e.target.value });
          }}
          cols={30}
        />

        <h4>Facebook Pixel Id</h4>
        <InputText
          value={props.state.facebookId}
          style={style.cardInput}
          onChange={(e) => {
            props.update({ facebookId: e.target.value });
          }}
          cols={30}
        />
      </>
    );
  }
}
