
import {CLOUD_BASE_URL} from '../constants/routes'
export async function getInvoice(stripeId){
    //visit?id=tSKhXkM5In6GwtqQWcL7 invoice-upcoming
    //http://127.0.0.1:5001/qrhome-38cd4/us-central1/app
     const url = `${CLOUD_BASE_URL}/invoice-upcoming?customerId=${stripeId}`
     try {
        console.log("getInvoice:url",url)
        const response = await fetch(url)
        console.log("getInvoice:response",response)
        const data = await response.json();
        return data;
    }
    catch(e){
        console.log('error fetching invoices',e)
    }
    
}


  