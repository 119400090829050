import React, { useEffect, useState, useRef} from 'react';
import {auth} from '../../firebase'
import {useAuth} from '../../contexts/AuthContext';
import ToastUtil from "../../components/ToastUtil/ToastUtil";
import {useHistory} from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer'

const ActionUrlScreen = () => {
  const [mode, setMode] = useState('');
  const [code, setCode] = useState('')
  const history = useHistory();


  useEffect(() => {
    // Get the action mode/code from the query parameters
    const mode = new URLSearchParams(window.location.search).get('mode');
    const code = new URLSearchParams(window.location.search).get('oobCode');
    setMode(mode);
    setCode(code);

  }, []);

  return (
    <>
      {mode == 'verifyEmail' && <VerifyEmailScreen actionCode={code}/>}
      {mode == 'resetPassword' && <ResetPasswordScreen actionCode={code}/>}
    </>
  )
}

const ResetPasswordScreen = (props) => {
  const [resetCompleted, setResetCompleted] = useState(false);
  const passwordRef1 = useRef();
  const passwordRef2 = useRef();
  const history = useHistory();

  const handleResetPassword = async (e, auth, actionCode) => {
    e.preventDefault();

    if(passwordRef1.current.value !== passwordRef2.current.value){
      ToastUtil.showMessage('error', 'Error', 'Passwords do not match!')
    }else{
        // Verify the password reset code is valid.
      auth.verifyPasswordResetCode(actionCode).then(() => {
        var newPassword = passwordRef1.current.value;
        // Save the new password.
        auth.confirmPasswordReset(actionCode, newPassword).then((resp) => {
          // Password reset has been confirmed and new password updated.
          setResetCompleted(true);
          history.push({pathname : '/signin', state: { passwordWasJustReset: true}})
        }).catch((error) => {
          // Error occurred during confirmation. The code might have expired or the password is too weak.
          ToastUtil.showMessage('error', error.code, error.message)
        });
      }).catch((error) => {
        // Invalid or expired action code. Ask user to try to reset the password again.
        ToastUtil.showMessage('error', error.code, error.message)
      });
    }
   
  }

  const styles = {
    backgroundColor: '#282b30',
    color: '#d9e3ea',
    textAlign:'center',
    fontSize:'30px',
    fontWeigth:'700',
    wordWrap: 'break-word',
    width:'90%'
  };


  return (
    <>
      
        <Navbar bgColor="#5f63ba"/>
        <header id="header" className="ex-2-header" style={{padding:"1rem"}}>
       
              <h1>Reset Password</h1>
             
              {/* <!-- Sign Up Form --> */}
              <div className="form-container" style={{marginTop:'50px'}}>
                <form onSubmit={(e) => { handleResetPassword(e, auth, props.actionCode); }}>
                  <div className="form-group">
                    <input
                      type="password"
                      ref={passwordRef1}
                      className="form-control-input notEmpty"
                      id="lemail"
                      required
                    />
                    {/* <label className="label-control" for="lemail">Email</label> */}
                    <label className="label-control">New Password</label>
                    <div className="help-block with-errors"></div>
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      ref={passwordRef2}
                      className="form-control-input notEmpty"
                      id="lpassword"
                      required
                    />
                    {/* <label className="label-control" for="lpassword">Password</label> */}
                    <label className="label-control">Confirm Password</label>
                    <div className="help-block with-errors"></div>
                  </div>
                  <div className="form-group">
                    <button
                      className="form-control-submit-button"
                      type="submit"
                      id="signup-button"
                    >
                      Reset Password
                    </button>
                  </div>
                </form>
              </div>
              {/* <!-- end of sign up form -->
                    <a className="white" href="account-recovery.html">Forgot your password ?</a> */}
          
      </header>
      
    </>
  );
}

const VerifyEmailScreen = (props) => {
  const [verificationCompleted, setVerificationCompleted] = useState(false);
  const history = useHistory();
  
  
  useEffect(() => {
    // Get the action code from the query parameters
    
    if (props.actionCode) {
      // Apply the code to verify the email
      auth
        .applyActionCode(props.actionCode)
        .then(() => {
          console.log('Email verification successful.');
          setVerificationCompleted(true);
          history.push({pathname : '/signin', state: { emailWasJustVerified: true}})
        })
        .catch(error => {
          ToastUtil.showMessage('error', 'Error Verifying Email', error)
          console.error('Error verifying email:', error);
        });
    }
  }, []);

  const styles = {
    backgroundColor: '#282b30',
    color: '#d9e3ea',
    textAlign:'center',
    fontSize:'30px',
    fontWeigth:'700',
    wordWrap: 'break-word',
    width:'90%',
    marginTop: '-20vh'
  };


  return (
    <>
      
      <Navbar bgColor="#282b30"/>

        <div style={{ height:'80vh', backgroundColor:"#282b30", display:'flex', alignItems:'center', justifyContent:'center' }}>
          
          <div  style={styles}>
             
                {/* <div style={{ display:'flex', justifyContent:'center', alignItems:'center'}}>
                  <div style={{ textDecoration: "none", fontSize: 30 }} className="navbar-brand logo-image">
                    <img src={Icon} width="60px" style={{marginTop:"-5px"}}/>
                    <text className={"Logo"}> qrhome</text>
                  </div>
                </div> */}
              
           

            {verificationCompleted ? 
              <>
                <div>Email verification completed!</div>
                <div>You can now sign in.</div>
              </>
            : 
              <div>Verifying your email...</div>
            }
          </div>
        </div>
        <Footer />
    </>
  );
};

export default ActionUrlScreen;
