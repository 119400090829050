import { useRef, useState } from "react";
import { Toast } from "primereact/toast";
// import {Navbar} from '@pkwy/react'
import Navbar from "../components/Navbar/Navbar";
import "firebase/auth";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import Logo from "../components/Logo";
import { GoogleLogin } from "@react-oauth/google";
import firebase from "firebase";
import ToastUtil from "../components/ToastUtil/ToastUtil";
import TermsNotice from "../components/TermsNotice/TermsNotice";
import LoadingUtil from "../components/LoadingDialog/LoadingUtil";
import {checkoutPlan} from "../stripe/createCheckoutSession"

export default function SignUpScreen() {
  let severity = "error";
  let summary = "Failed to sign up";
  let detail = "Be sure to use a valid email";
  const emailRef = useRef();
  const passwordRef = useRef();
  const {
    signupWithEmail,
    verifyEmail,
    googleOAuthSignInOrSignUp,
    setLoggedIn,selectedPlan,autoNavPlans
  } = useAuth();

  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const history = useHistory();
  const showMessage = (severity, summary, detail) => {
    toast.current.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 9000,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); 

    console.log(`handleSubmit ${emailRef}`);
    // e.preventDefualt();
    try {
      setLoading(true);
      await signupWithEmail(emailRef.current.value, passwordRef.current.value);
      summary = "Failed to send email verification";
      await verifyEmail();
      severity = "info";
      summary = "Email verification sent";
      detail = "Check email for verification link";
      showMessage(severity, summary, detail);
      setTimeout(() => {
        history.push("/signin");
      }, 3000);
    } catch (e) {
      console.log(`catch signup ${JSON.stringify(e)}`);
      showMessage(severity, summary, e.message);
    }

    setLoading(false);
  }

  return (
    <div>
      <Toast ref={toast} position="bottom-right" />
      {/* <Navbar LogoComp = {Logo} bgColor="#5f63ba" handleLogoClick={()=>{history.push('/')}} handleSignUpClick={()=>{history.push('/signup');}} handleSignInClick={()=>{history.push('/signin');}}/> */}
      <Navbar bgColor="#5f63ba" />

      <header id="header" className="ex-2-header" style={{ padding: "1rem" }}>
        <h1>Sign Up</h1>
        <p>
          Already signed up? Then just{" "}
          <Link className="white" style={{ fontWeight: "bold" }} to="/signin">
            Login
          </Link>
        </p>
        {/* <!-- Sign Up Form --> */}
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <GoogleLogin
                onSuccess={async (googleUser) => {
                  console.log("googleUser", JSON.stringify(googleUser));
                  const { credential } = googleUser;
                  try {
                    const googleCredential =
                      firebase.auth.GoogleAuthProvider.credential(credential);
                    const userCredential = await firebase
                      .auth()
                      .signInWithCredential(googleCredential);
                    const user = userCredential.user;
                    console.log("User signed up or signed in with Google:", user);
                    await googleOAuthSignInOrSignUp(user);
                    console.log("Successful Login");
                    setLoggedIn(true);
                    if(selectedPlan){
                      LoadingUtil.show()
                      checkoutPlan(user.uid,selectedPlan)
                      autoNavPlans(null)
                    } else {
                      history.push("/dashboard");
                    }
                  } catch (error) {
                    // Handle error
                    ToastUtil.showError("Error signing in", error);
                    console.error(
                      "Error signing up or signing in with Google:",
                      error
                    );
                  }
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            </div> 
            <div style={{ margin: "12px 0px" }}>or</div>
            <div className="form-group">
              <input
                type="email"
                ref={emailRef}
                className="form-control-input notEmpty"
                id="lemail"
                required
              />
              {/* <label className="label-control" for="lemail">Email</label> */}
              <label className="label-control">Email</label>
              <div className="help-block with-errors"></div>
            </div>
            <div className="form-group">
              <input
                type="password"
                ref={passwordRef}
                className="form-control-input notEmpty"
                id="lpassword"
                required
              />
              {/* <label className="label-control" for="lpassword">Password</label> */}
              <label className="label-control">Password</label>
              <div className="help-block with-errors"></div>
            </div>
            <div className="form-group">
              <button
                disabled={loading}
                type="submit"
                className="form-control-submit-button"
                id="signup-button"
              >
                SIGN UP
              </button>
            </div>
            <div style={{ fontSize: "9px", color: "grey" }}>
              By clicking Sign Up you agree to our{" "}
              <a href="/terms" style={{ color: "#5f63ba" }}>
                Terms and Conditions
              </a>{" "}
              and{" "}
              <a href="/terms" style={{ color: "#5f63ba" }}>
                Privacy Policy
              </a>
            </div>
          </form>
        </div>
        {/* <!-- end of sign up form -->
                    <a className="white" href="account-recovery.html">Forgot your password ?</a> */}
      </header>
    </div>
  );
}
