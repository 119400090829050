import { useAuth } from "../../../contexts/AuthContext";

import { InputText } from "primereact/inputtext";

export default function EditTags(props) {
  const { activeSubscription, user } = useAuth();
  const hide = user.orgId || !activeSubscription;

  if (hide) {
    return null;
  } else {
    return (
      <>
        <div style={{ marginTop: 10, marginBottom: 2 }}>Google Tag</div>
        <InputText
          style={{ width: "100%" }}
          value={props.googleId}
          onChange={(e) => props.setGoogleId(e.target.value)}
        />
        <div style={{ marginTop: 10, marginBottom: 2 }}>Facebook Tag</div>
        <InputText
          style={{ width: "100%" }}
          value={props.facebookId}
          onChange={(e) => props.setFacebookId(e.target.value)}
        />
      </>
    );
  }
}
