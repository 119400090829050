// @ts-nocheck
import { useEffect, useRef, useState } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { Toast } from "primereact/toast";
import "../ManageCampaign.css";
import { useAuth } from "../../../contexts/AuthContext";
import { clearQRStats } from "../../../data/CampaignService";
import {HitsBarChart} from '../../../components/HitsBarChart/HitsBarChart'
interface Props {
  affiliateArray: [];
  title: string;
  url: string;
  description: string;
  budget: number;
  cpc: number;
  attachments: any[];
  agreeTerms: boolean;
  status: string;
  allowedAffiliateIds: [];
  affiliates: [];
  clicks: 0;
  owner: string; //"rZZqz3ggegcboepho5RIiDtwJUb2";
  max: number; //0;
  id: string; //"on0DBk3Ax8g5gy2BstfU";
}
var spamBlocked = false;
export default function ManageAffiliatesCard(props: Props) {
  const { resetCampaignStats, pullUpdatesToUserCampagins } = useAuth();
  console.log(`ManageAffiliatesCard:props, ${JSON.stringify(props)}`);
  // const [affiliates, setAffiliates] = useState(props.campaign.affiliateArray);
  const [classStyle, updateClassStyle] = useState("pi  pi-refresh");
  useEffect(() => {
    console.log("ManageAffiliatesCard: props updated");
    // setAffiliates(props.campaign.affiliateArray)
  }, [props.campaign]);
  // ~ confirm button ~
  const toast = useRef(null);

  const accept = async () => {
    await clearQRStats(props.campaign);
    resetCampaignStats(props.campaign);
    props.updateTimes([]);
    // setAffiliates([ { clicks: 0, times: [], source: "Link" },
    // { times: [], clicks: 0, source: "QR" }])
    toast.current.show({
      severity: "info",
      summary: "Confirmed",
      detail: "QR stats have been reset",
      life: 3000,
    });
  };
  const refresh = async () => {
    updateClassStyle("pi pi-spin pi-refresh");
    if (!spamBlocked) {
      console.log("not blocked");
      spamBlocked = true;
      setTimeout(() => {
        spamBlocked = false;
      }, 8000);
      const response = await pullUpdatesToUserCampagins();
      console.log("refresh:response", JSON.stringify(response));
      console.log("props.campaignIndex", JSON.stringify(props.campaignIndex));
      console.log(
        "props.campaign",
        JSON.stringify(response[props.campaignIndex])
      );

      if (response.length > props.campaignIndex) {
        console.log("response.length > props.campaignIndex");
        const update = response[props.campaignIndex];
        console.log("network updates");
        props.updateCampaign(update);
      }
    } else {
      console.log("Spam Blocked");
    }

    updateClassStyle("pi pi-refresh");
  };
  const reject = () => {
    // toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
  };

  const confirm1 = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to clear all records for this QR?",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
    });
  };

  // ~ ~

  return (
    <Card style={{ padding: "0px 5px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            marginTop: "-10px",
            fontSize: "1.5rem",
            fontWeight: "700",
            justifySelf: "left",
            marginBottom: "5px",
          }}
        >
          Reset / Refresh
        </div>
        <div onClick={refresh}>
          <i className={`${classStyle}`} style={{ fontSize: "1.25rem" }}></i>
        </div>
      </div>
      <div style={{ marginBottom: "10px", maxWidth: 550 }}>
        All QR codes you create also can be used as just links. Clear traffic
        data to reuse the QR.
      </div>

      <Toast ref={toast} />
      <ConfirmPopup />
      <div>
        <Button onClick={confirm1} label="Reset Traffic"></Button>
      </div>
      <HitsBarChart times = {props.times}/>
    </Card>
  );
}
