import React, { Component, useEffect } from "react";
import { Button } from 'primereact/button';
import Footer from '../../components/Footer/Footer'
import Navbar from '../../components/Navbar/Navbar';
// import {Navbar} from '@pkwy/react'
import { Link, useHistory } from "react-router-dom";
import Lottie from "react-lottie";
import Scan from "../../lotties/Scan.json";
import maps from "../../lotties/maps.json";
import ScanSign from "../../lotties/ScanSign.json";

import Analytics from '../../lotties/Analytics.json'
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { BsPlug } from "react-icons/bs";
import { MdTrackChanges } from "react-icons/md";
import { AiOutlineLink } from "react-icons/ai";
import PayWallUtil from "../../components/DialogModal/DialogModalUtil";
import TEXT from "../../constants/Text";
import LoadingUtil from "../../components/LoadingDialog/LoadingUtil";

const defaultOptionsScan = {
  loop: true,
  autoplay: true,
  animationData: Scan,
  rendererSettings: {
    // preserveAspectRatio: "xMidYMid slice",
  },
};
const defaultOptionsMap = {
  loop: true,
  autoplay: true,
  animationData: maps,
  rendererSettings: {
    // preserveAspectRatio: "xMidYMid slice",
  },
};
const defaultOptionsScanSign = {
  loop: true,
  autoplay: true,
  animationData: ScanSign,
  rendererSettings: {
    // preserveAspectRatio: "xMidYMid slice",
  },
};
const defaultOptionsAnalytics = {
  loop: true,
  autoplay: true,
  animationData: Analytics,
  rendererSettings: {
    // preserveAspectRatio: "xMidYMid slice",
  },
};

export default function LandingScreen() {
  const history = useHistory();
  const action = ()=>{
    LoadingUtil.close();
  }
  useEffect(()=>{

  },[])

    return (
      <div style ={{backgroundColor:"#282b30"}}>
       
        
      {/* <Navbar bgColor="#282b30" handleLogoClick={()=>{}} handleSignUpClick={()=>{history.push('/signup');}} handleSignInClick={()=>{history.push('/signin');}}/> */}
      <Navbar bgColor="#282b30"/>
        <div style={{backgroundColor:"#282b30",  color:"#d9e3ea",}}>
          <div style={{textAlign:"center",marginBottom:'20px'}}>
            <h1 style={{ fontWeight:800}}>QR Code Marketing For Realtors</h1>
            <div style={{display:'flex',justifyContent:'center'}}>
            <h6 style={{textAlign:'center',width:'80%',justifyContent:'center',alignSelf:'center'}}>Scan, Sell, Succeed: Elevate Your Real Estate Marketing</h6>

            </div>
            <div style={{display:"flex", justifyContent:"center", height:"30px", marginTop:"20px"}}>
              <Link to="/signup" style={{margin:'0px 10px'}}>
                <Button style={{borderRadius:"0px", color: "#d9e3ea"}}  label="Get Started Today" />
              </Link>
              {/* <a href="https://calendly.com/qrhome/live-demo" style={{margin:'0px 10px'}}>
                <Button style={{borderRadius:"0px", color: "#d9e3ea"}}  label="Book a Demo" />
              </a> */}
            </div>
          </div>

          <div style={{display:'flex', width:'100%', justifyContent:'center', alignItems:'center', margin:'80px 0px'}} >
            <div style={{ maxWidth: '75%'}}>
              <Lottie  options={defaultOptionsScanSign}/>
            </div>
          </div>

          <div style={{padding:"0% 10%", paddingTop:"15px", textAlign:"center"}}>
            <h1 style={{fontWeight:800}}>Create smart QR codes for your listings, flyers, signs, and more</h1>
            {/* <div style={{fontSize:"1.1rem", paddingTop:"5px"}}>Invite only based campaigns allow you to compensate affiliates</div> */}
          </div>

          <div style={{padding:"0% 5% 2.5% 5%", display:"flex", justifyContent:"center", alignItems:"center", flexWrap: "wrap", alignContent:"space-around"}}>
           <div style={{marginTop:"50px", alignItems:"center", display:"flex", flexDirection:"column", flex: "1 0 250px", padding:"0px 10px"}}>
            <div style={{margin:"5px", backgroundColor:"#6366f1", borderRadius:"30px", width:"60px", height:"60px", display:"flex", justifyContent:"center", alignItems:"center" }}>
              <AiOutlineAppstoreAdd size={30} color="white"/>
            </div>
            <div style={{margin:"5px", fontSize:"1.8rem", fontWeight:700}}>Retargeting</div>
            <div style={{margin:"5px", textAlign:"center", fontSize:"1.25rem"}}>Google & Facebook Ad Tracking</div>
           </div>
           <div style={{marginTop:"50px", alignItems:"center", display:"flex", flexDirection:"column", flex: "1 0 250px", padding:"0px 10px"}}>
            <div style={{margin:"5px",backgroundColor:"#6366f1", borderRadius:"30px", width:"60px", height:"60px", display:"flex", justifyContent:"center", alignItems:"center" }}>
              <AiOutlineLink size={30} color="white" />
            </div>
            <div style={{margin:"5px", fontSize:"1.8rem", fontWeight:700}}>Dynamic</div>
            <div style={{margin:"5px",textAlign:"center", fontSize:"1.25rem"}}>Generate & Manage Dynamic QR Codes </div>
           </div>
           <div style={{marginTop:"50px", alignItems:"center", display:"flex", flexDirection:"column", flex: "1 0 250px", padding:"0px 10px"}}>
            <div style={{margin:"5px",backgroundColor:"#6366f1", borderRadius:"30px", width:"60px", height:"60px", display:"flex", justifyContent:"center", alignItems:"center" }}>
              <BsPlug size={30} color="white" />
            </div>
            <div style={{margin:"5px", fontSize:"1.8rem", fontWeight:700}}>Plug-in-Play</div>
            <div style={{margin:"5px",textAlign:"center", fontSize:"1.25rem"}}>No Technical Knowledge Required</div>
           </div>
           <div style={{marginTop:"50px", alignItems:"center", display:"flex", flexDirection:"column", flex: "1 0 250px", padding:"0px 10px"}}>
            <div style={{margin:"5px",backgroundColor:"#6366f1", borderRadius:"30px", width:"60px", height:"60px", display:"flex", justifyContent:"center", alignItems:"center" }}>
              <MdTrackChanges size={30} color="white"/>
            </div>
            <div style={{margin:"5px", fontSize:"1.8rem", fontWeight:700}}>Track Traffic</div>
            <div style={{margin:"5px",textAlign:"center", fontSize:"1.25rem"}}>View Metrics & Gain Insights</div>
           </div>
          </div>

          <div style={{ margin:"5% 5%", height:"2px", width:"90%", backgroundColor:"#6366f1", opacity:"0.25"}}/>
      
          <div style={{padding:"0% 10%", textAlign:"center"}}>
            <h1 style={{fontWeight:800,color:'white'}}>Provide Data To Your Sellers</h1>
            <div style={{fontSize:"1.35rem", paddingTop:"7.5px"}}>Our users have discovered that using <b>qrhome </b> QR codes on their 'For Sale' signs is an exceptionally effective strategy. When potential buyers scan these codes to access property details, the event is recorded, allowing agents to share insights to their sellers. This is just one more way agents use <b>qrhome</b> to seperate themselves from other agents.</div>
          </div>
          <div style={{ margin:"5% 5%", height:"2px", width:"90%", backgroundColor:"#6366f1", opacity:"0.25"}}/>

          <div style={{padding:"0% 10%", textAlign:"center"}}>
            <h1 style={{fontWeight:800,color:'white'}}>Add Map Directions To Open House Signs</h1>
            <div style={{fontSize:"1.35rem", paddingTop:"7.5px"}}>Agents impress both buyers and sellers by incorporating <b>qrhome </b> QR codes into their open house signs. Through the <b>qrhome</b> dashboard, they can easily customize the maps directions for each open house they host, efficiently guiding buyers to the property and extending the reach of effective buyer navigation. </div>
          </div>
          <div style={{ margin:"5% 5%", height:"2px", width:"90%", backgroundColor:"#6366f1", opacity:"0.25"}}/>

          <div style={{padding:"2.5% 5%", textAlign:"left", display:"flex", alignContent:"space-between", flexWrap: "wrap" }}>
            <div style={{margin:"20px", flex:"1 0 50%"}}>
              <div style={{fontSize:"1.75rem", color:"#6366f1", fontFamily:"Morgan-Chalk"}}>Less spend</div>
              <div style={{fontSize:"2.2rem", fontWeight:700}}>Change QR Destination</div>
              <div style={{fontSize:"1.25rem", margin:"10px 0px"}}>No need to reprint, change the QR destination.</div>
              <div style={{margin:"5px 0px", fontSize:"1.15rem"}}>✔️ Update previously sent-out marketing</div>
              <div style={{margin:"5px 0px", fontSize:"1.15rem"}}>✔️ Great for 'For Sale' Signs</div>
              <div style={{margin:"5px 0px", fontSize:"1.15rem"}}>✔️ Incorporate Map Directions on Your Open House Signs</div>

              <div style={{margin:"5px 0px", fontSize:"1.15rem"}}>✔️ Gain insights into engagement</div>
            </div>
            
            <div style={{margin:"0 20px", flex:"1 0 40%", display:'flex', justifyContent:'center', alignItems:'center'}}>
              <div style={{maxWidth:"50%"}} >
                <Lottie  options={defaultOptionsMap}/>
              </div>
            </div>

          </div>

          <div style={{padding:"0% 5% 2.5% 5%", textAlign:"left", display:"flex", alignContent:"space-between", flexWrap: "wrap-reverse"}}>

            <div style={{margin:"0 20px", flex:"1 0 40%", display:'flex', justifyContent:'center', alignItems:'center'}}>
              <div style={{maxWidth:"70%"}} >
                <Lottie options={defaultOptionsAnalytics} />
              </div>
            </div>

            <div style={{margin:"20px", flex:"1 0 50%"}}>
              <div style={{fontSize:"1.75rem", color:"#6366f1", fontFamily:"Morgan-Chalk"}}>Metrics</div>
              <div style={{fontSize:"2.2rem", fontWeight:700}}>See Your Traffic</div>
              <div style={{fontSize:"1.25rem", margin:"10px 0px"}}>Our QR codes track scans of your flyers, signs, and more. Discover if people are actually engaging with your mailouts and farming materials.</div>
              <div style={{margin:"5px 0px", fontSize:"1.15rem"}}>✔️ Overall visits generated through QR Codes</div>
              <div style={{margin:"5px 0px", fontSize:"1.15rem"}}>✔️ When traffic is being generated</div>
              <div style={{margin:"5px 0px", fontSize:"1.15rem"}}>✔️ Where to focus your promotions</div>
            </div>
           
          </div>

       

        </div>

        <Footer />
        
      
        </div>

    );
  
}
