// @ts-nocheck
import Navbar from "../../components/Nav/Navbar";
import { useHistory } from "react-router-dom";
import CampaignCard from "../../components/Cards/CampaignCard";
import Panel from "../../components/Panels/Panel";
import Lottie from "react-lottie";
import Home from "../../lotties/Home.json";
import TEXT from "../../constants/Text";
import { useAuth } from "../../contexts/AuthContext";
import { useEffect, useState } from "react";
import { screenStyles } from "../ScreenStyles";
import DialogModalUtil from "../../components/DialogModal/DialogModalUtil";
import { createCheckoutSession } from "../../stripe/createCheckoutSession";
import { ROLES } from "../../constants/Roles";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: Home,
  rendererSettings: {
    // preserveAspectRatio: "xMidYMid slice",
  },
};
const styles = {
  footer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bottom: 0,
    left: 0,
    width: "100%",
  },
};
export default function DashboardScreen() {
  const history = useHistory();
  const { logout, user, campaigns, premiumStatus,activeSubscription } = useAuth();
  console.log('DashboardScreen:user',JSON.stringify(user))
  const navTitle = user.role === ROLES.OWNER ? "QRs":"Dashboard"
  const createSession = () =>{
    createCheckoutSession(user.uid);
  }
  const handleNewButtonClick = () => {
    console.log("qr limit: ", user.maxQRCount);
    console.log("# campaigns", campaigns.length);
    console.log("handleNewButtonClick:activeSubscription", activeSubscription);
   
 if(campaigns.length < 15 && (user.dev || (user.orgId && user.role === ROLES.MEMBER)) ){
     history.push("/newCampaign");
   }
   else if(campaigns.length >= 15 && (user.dev || (user.orgId && user.role === ROLES.MEMBER))){
      DialogModalUtil.showAccountRestriction(TEXT.QR_LIMIT_HEADER,TEXT.QR_LIMIT_MEMBERS_BODY)
   }
   else if(campaigns.length < 15 && (user.orgId && user.role === ROLES.ADMIN)){
    DialogModalUtil.showAccountRestriction(TEXT.ACCOUNT_RESTRICTION,TEXT.ADMIN_ACCOUNT);
   }
   else if (activeSubscription && campaigns.length < 15  ) {
      console.log("handleNewButtonClick:user", user);
      history.push("/newCampaign");
      //non premium users has already used up their free qr
    } else if (activeSubscription && campaigns.length >= 15) {
      // setPayWallOpen(true);
      DialogModalUtil.showCustomerServiceRequired(TEXT.QR_LIMIT_HEADER,TEXT.QR_LIMIT_BODY);
      console.log("Subscription limit of 15 QR codes reached.");
    } else {
      // not activeSubscription
      DialogModalUtil.showCallToAction(TEXT.PAYWALL_HEADER,TEXT.PAYWALL_BODY_FREE_TRIAL,createSession,TEXT.PAYWALL_SUBHEADER_FREE_TRIAL);
  }

    console.log("handleNewButtonClick:user", user);
    console.log("handleNewButtonClick:campaigns", campaigns);
  };
  
  return (
    <div
      style={{ minHeight: "100vh", backgroundColor: "#EFEFEF", width: "100%" }}
    >
      <div
        style={{ flex: 1, paddingTop: "80px", width: "100%", height: "100%" }}
      >
        <Navbar
          leftClick={() => {
            console.log("hello");
            history.push("/dashboard");
          }}
          rightClick={async () => {
            console.log("signout");
            await logout();
            history.push("/");
          }}
          userIsPremium={premiumStatus}
          pageTitle={navTitle}
          rightNavTitle={"Signout"}
        />
        <div style={screenStyles.body}>
          <Panel
            data={campaigns}
            buttonHandler={handleNewButtonClick}
            buttonTitle={"New QR"}
            title={"Smart QRs"}
            buttonIcon={"pi pi-plus"}
            component={CampaignCard}
            emptyMessage={TEXT.NO_QRS_MESSAGE}
          />
          {/* <Panel data = {user.userCampaigns} buttonHandler = {()=>{history.push("/newCampaign") }}  buttonTitle={"New Listing"} title = {"Listings"} component={CampaignCard} emptyMessage={"No Campaings"}/> */}

          {/* <Panel data = {user.signQRs} title = {"My Signs"} component={AffiliateCard} emptyMessage={"No affiliated campaigns"} /> */}
          <div style={{ padding: "30px" }}></div>
        </div>
      </div>

      <div style={styles.footer}>
        <div style={{ maxWidth: "75%" }}>
          <Lottie options={defaultOptions} />
        </div>
      </div>
    </div>
  );
}
