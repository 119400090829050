import { useEffect, useState, useRef } from "react";
import Navbar from "../../components/Nav/Navbar";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import PanelSingleCard from "../../components/Panels/PanelSingleCard";
import ManageAffiliatesCard from "../../components/Cards/ManageAffiliatesCard/ManageAffiliatesCard";
import ManageDetailsCard from "../../components/Cards/ManageDetailsCard/ManageDetailsCard";
import { update } from "../../data/CampaignService";
import { COLLECTION } from "../../constants/Text";
import { screenStyles } from "../ScreenStyles";
import { QRCodeSVG } from "qrcode.react";
import { ChromePicker } from "react-color";
import "primeicons/primeicons.css";

import { Button } from "primereact/button";
import { getDailyBuckets } from "../../utils/dateUtils";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { Card } from "primereact/card";
import { getAffiliatedUrl } from "../../utils/CampaignUtils";
const saveSvgAsPng = require("save-svg-as-png");

function QR2(props) {
  console.log("QR2:props:", JSON.stringify(props));
  const { updateCampaignDetails } = useAuth();
  const [FGColor, setFGColor] = useState(props.qrFGColor);
  const [displayFGColorPicker, setDisplayFGColorPicker] = useState(false);
  const [BGColor, setBGColor] = useState(props.qrBGColor);
  const [displayBGColorPicker, setDisplayBGColorPicker] = useState(false);

  const handleChangeComplete = (field, value) => {
    console.log("ManageCampaignScreen:handleChangeComplete", {
      [field]: value,
    });
    const updates = { [field]: value };
    let theUpdate = { ...props, ...updates };
    console.log(
      "ManageCampaignScreen:QR2:handleChangeComplete:theUpdate",
      theUpdate
    );
    update(COLLECTION.CAMPAIGNS, props.id, updates);
    updateCampaignDetails(theUpdate);
    props.updateCampaign(theUpdate);
  };

  const imageOptions = {
    scale: 5,
    encoderOptions: 1,
  };

  const toPNG = () => {
    console.log("Calling toPNG");
    let svg = document.querySelector("#svgQR");
    saveSvgAsPng.saveSvgAsPng(svg, "qr-code.png", imageOptions);
    // svg download
    handleDownloadSvG();
  };

  const handleDownloadSvG = () => {
    const svgElement = document.getElementById("svgQR"); // Using getElementById to reference the SVG element

    if (svgElement) {
      // Convert the SVG to a data URL
      const svgData = new XMLSerializer().serializeToString(svgElement);
      const blob = new Blob([svgData], { type: "image/svg+xml" });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "qrcode.svg";
      a.click();

      URL.revokeObjectURL(url);
    }
  };

  const link = getAffiliatedUrl(props.id, props.QR);
  return (
    <Card style={{ padding: "0px 5px" }}>
      <div
        style={{
          marginTop: "-10px",
          fontSize: "1.5rem",
          fontWeight: "700",
          justifySelf: "left",
          marginBottom: "5px",
        }}
      >
        Download or ScreenShot
      </div>

      <div style={{ marginBottom: "10px", maxWidth: 550 }}>
        This is your dynamic QR. Include it on signs, cards and marketing
        material. The QR will not change, yet you can change where it leads to
        at anytime. Make printed material highly reusable and trackable.
        <div
          style={{
            marginTop: "10px",
            fontSize: "1.5rem",
            fontWeight: "700",
            justifySelf: "left",
          }}
        >
          Common Uses
        </div>
        <div style={{ paddingTop: "10px" }}>
          Realtors use these as google maps links for open house signs, LinkedIn
          links on business cards, personal sites, and other marketing material.
        </div>
      </div>
      <div
        style={{
          fontSize: "1.5rem",
          fontWeight: "700",
          justifySelf: "left",
          margin: "30px 0px",
          marginBottom: "10px",
        }}
      >
        <QRCodeSVG
          id="svgQR"
          value={link}
          size={256}
          bgColor={BGColor}
          fgColor={FGColor}
          level={"L"}
          includeMargin={true}
        />

        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            className="swatch"
            style={{
              marginTop: "20px",
              marginRight: "10px",
              padding: "5px",
              background: "#fff",
              borderRadius: "3px",
              display: "inline-block",
              cursor: "pointer",
              boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
            }}
            onClick={() => setDisplayFGColorPicker(!displayFGColorPicker)}
          >
            <div
              style={{
                width: "36px",
                height: "30px",
                borderRadius: "2px",
                background: FGColor,
              }}
            />
          </div>
          {displayFGColorPicker ? (
            <div style={{ position: "absolute", zIndex: "2" }}>
              <div
                style={{
                  position: "fixed",
                  top: "50px",
                  right: "0px",
                  bottom: "0px",
                  left: "0px",
                }}
                onClick={() => setDisplayFGColorPicker(false)}
              />
              <ChromePicker
                color={FGColor}
                presetColors={[]}
                disableAlpha={true}
                onChange={(color) => {
                  setFGColor(color.hex);
                }}
                onChangeComplete={(color) =>
                  handleChangeComplete("qrFGColor", color.hex)
                }
              />
            </div>
          ) : null}

          <div
            className="swatch"
            style={{
              marginTop: "20px",
              marginRight: "10px",
              padding: "5px",
              background: "#fff",
              borderRadius: "3px",
              display: "inline-block",
              cursor: "pointer",
              boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
            }}
            onClick={() => setDisplayBGColorPicker(!displayBGColorPicker)}
          >
            <div
              style={{
                width: "36px",
                height: "30px",
                borderRadius: "2px",
                background: BGColor,
              }}
            />
          </div>
          {displayBGColorPicker ? (
            <div style={{ position: "absolute", zIndex: "2" }}>
              <div
                style={{
                  position: "fixed",
                  top: "50px",
                  right: "0px",
                  bottom: "0px",
                  left: "0px",
                }}
                onClick={() => setDisplayBGColorPicker(false)}
              />
              <ChromePicker
                color={BGColor}
                presetColors={[]}
                disableAlpha={true}
                onChange={(color) => {
                  setBGColor(color.hex);
                }}
                onChangeComplete={(color) =>
                  handleChangeComplete("qrBGColor", color.hex)
                }
              />
            </div>
          ) : null}

          <Button
            label="Download"
            style={{
              marginTop: "20px",
              height: 40,
              fontSize: 12,
              display: "block",
            }}
            onClick={toPNG}
          />
        </div>
      </div>
    </Card>
  );
}

export default function ManageCampaignScreen() {
  const location = useLocation();
  const { campaigns } = useAuth();

  const myProp = location.state && location.state;
  const [times, updateTimes] = useState(campaigns[myProp.index].times);
  const c = { ...campaigns[myProp.index] };
  console.log("ManageCampaignScreen:cx", JSON.stringify(c));
  const [campaign, updateCampaign] = useState({ ...campaigns[myProp.index] });

  const updateReducer = (value) => {
    console.log(
      "ManageCampaignScreen:called updateReducer",
      JSON.stringify(value)
    );
    const update = { campaign, ...value };
    updateTimes(update.times);
    updateCampaign(update);
  };

  console.log("ManageCampaignScreen:props", myProp);
  const history = useHistory();
  return (
    <div
      style={{
        flex: 1,
        paddingTop: "80px",
        backgroundColor: "#EFEFEF",
        width: "100%",
        height: "100%",
      }}
    >
      <Navbar
        leftClick={() => {
          console.log("hello");
          history.push("/dashboard");
        }}
        pageTitle={"Manage"}
      />
      <div style={screenStyles.body}>
        <PanelSingleCard
          {...campaign}
          component={ManageDetailsCard}
          panelTitle={"Details"}
        />
        <PanelSingleCard
          campaign={campaign}
          times={times}
          panelTitle={"Impressions"}
          updateTimes={updateTimes}
          campaignIndex={myProp.index}
          updateCampaign={updateReducer}
          component={ManageAffiliatesCard}
        />
        {/* <PanelSingleCard times = {times} panelTitle = {""} component={HitsBarChart}/> */}
        <PanelSingleCard
          {...campaign}
          panelTitle={"QR"}
          component={QR2}
          updateCampaign={updateCampaign}
        />

        <div style={{ padding: "200px" }}></div>
      </div>
    </div>
  );
}
