import React, { useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { createCheckoutSession } from "../../stripe/createCheckoutSession";
import {useAuth} from '../../contexts/AuthContext';
import Contact from '../../assets/images/contact.png'
import Upgrade from '../../assets/images/upgrade.png'
import { useWindowSize} from '@react-hook/window-size'
import { ProgressSpinner } from 'primereact/progressspinner';

const ModalStyles = {
    modal: {
        position: 'fixed',
        top: 0,
        left: 0,
        width:'100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.6)',
        zIndex:9999
      },
      
      modalMain: {
        position:'fixed',
        // background: 'white',
        width:'auto',
        height: 'auto',
        top:'50%',
        left:'50%',
        transform: 'translate(-50%,-50%)',
      },
      
      displayBlock: {
        display:'block',
      },
      
      DisplayNone: {
        display: 'none',
      }
}
export const Modal = ({ show, children }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";
  
    return (
        <>
        { show ?
         <div style={ModalStyles.modal}>
         <section style={ModalStyles.modalMain}>
           {children}
         </section>
       </div>:null }
        </>
        
     
    );
  };

export default class LoadingDialog extends React.Component {
    constructor(props){
        super(props)
        this.state ={
            visible:false
        }
    }
    show(value){
        this.setState({visible:value})
    }
    render(){
    return (
        <>
        { this.state.visible ?
          <div className="card flex justify-content-center">
            
            <Modal show= {true}>
                <ProgressSpinner />
            </Modal>
            </div> :null
    }
        </>
      
    )
    }
}