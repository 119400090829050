import React, { useContext, useState, useEffect } from "react";
import {
  db,
  auth,
  storageRef,
  isUserPremium,
  hasActiveSubscription,
} from "../firebase";
import firebase from "firebase";
import {
  collectAffiliates,
  getAllUserCampaigns,
} from "../data/CampaignService";
import { ROLES } from "../constants/Roles";
import { ORG_CONFIG } from "../constants/OrgConfig";
import { getInvoice } from "../data/PaymentService";
import {convertToCurrency,localDateTimeString} from '../utils/general'
const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(); // login auth
  const [user, setUser] = useState(null); // firestore
  const [campaigns, setCampaigns] = useState(null);
  const [loggedIn, setLoggedIn] = useState(null);
  const [premiumStatus, setPremiumStatus] = useState(false);
  const [activeSubscription, setActiveSubscription] = useState(null);
  const [selectedPlan,autoNavPlans] = useState(null)
  const[org,setOrg]= useState(null)
  /**
   * SaaS
   * @param {*} email
   * @param {*} password
   * @returns
   */
  function signupWithEmail(email, password) {
    return auth
      .createUserWithEmailAndPassword(email, password)
      .then(async (cred) => {
        console.log("signupWithEmail:userCredential.user.uid;", cred.user.uid);
        const docRef = db.collection("admin").doc(cred.user.uid);

        // Check if the document exists
        docRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              const data = doc.data();
              console.log("doc.exists: Document data:", data);
              docRef.update({
                uid: cred.user.uid,
                email: email,
                campaignIds: [],
              });
            } else {
              console.log("Document does not exist");
              docRef.set({
                uid: cred.user.uid,
                email: email,
                campaignIds: [],
                role:'admin',
                orgId:''
              });
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      });
  }

  function login(email, password) {
    return new Promise((resolve, reject) => {
      resolve(auth.signInWithEmailAndPassword(email, password));
    });
  }

  /**
   * SaaS
   * @param {*} uid
   * @param {*} password
   * @returns
   */

  async function getUserCampaigns(uid) {
    let usersCampaigns = [];
    usersCampaigns = await getAllUserCampaigns(uid);
    await collectAffiliates(usersCampaigns);
    return usersCampaigns;
  }
  async function pullUpdatesToUserCampagins() {
    if (user && user.uid) {
      const updatedCampagins = await getUserCampaigns(user.uid);
      setCampaigns(updatedCampagins);
      return updatedCampagins;
    }
  }
  /**
   * SaaS
   * @param {*} email
   * @param {*} password
   * @returns
   */

  async function getUserByUID(uid) {
    console.log("getUserByUID", uid);
    const user = await db.collection("admin").doc(uid).get();
    await buildExistingUserObject(user);
  }
  async function getOrgDetails(orgId){
    console.log("getOrgDetails", orgId);
    try{
      const org = (await db.collection("org").doc(orgId).get()).data();
      return org
    } catch(e) {
      console.log('getOrgDetails:error',e);
    }
    
  }
  async function googleOAuthSignInOrSignUp(user) {
    console.log("googleOAuthSignInOrSignUp:user", JSON.stringify(user));
    const admin = await db.collection("admin").doc(user.uid);
    const snapshot = await admin.get();
    console.log("googleOAuthSignInOrSignUp:admin", JSON.stringify(admin));
    // signin of existing user
    // stipe creates a user so to know if it is a complete user
    // we must also check at least one required property
    if (snapshot.exists && snapshot.data().uid) {
      console.log("signin of existing user");
      await buildExistingUserObject(snapshot);
      return;
    } else {
      // new user
      // update the admin in network
      console.log("signup of new user with googel provider");
      const data = await buildNewUserObject(user.uid, user.email);
      console.log('newUserData',JSON.stringify(data))

      await admin.set({ ...data }, { merge: true }); // Use merge option to update existing data or create if it doesn't exist
      return;
    }
  }
  async function buildExistingUserObject(admin) {
    console.log("buildExistingUserObject");
    const uid = admin.data().uid;
    const userData = admin.data();
    const orgAdmin = userData.orgId && userData.role === ROLES.ADMIN;
    const orgOwner = userData.role === ROLES.OWNER;
    var billing = {amountDue:-1}
    console.log(`isorgAdmin`,orgAdmin)
    console.log(`isorgOwner`,orgOwner)

    // Get Org Collection Data
    if(orgAdmin || orgOwner){
      const orgDetails = await getOrgDetails(userData.orgId)
      console.log('orgeDetails',orgDetails)
      setOrg({...orgDetails})
    }
    // Billing Info For Elgible Users
    if((orgAdmin && ORG_CONFIG.ADMIN_BILLING_ACCESS) || orgOwner){
      console.log('invoice:stripeId',userData.stripeId);
      const invoice = await getInvoice(userData.stripeId);
      console.log('stripe invoice',invoice);
      console.log('stripe invoice json',JSON.stringify(invoice));
      billing.amountDue = invoice.amount_due;
      billing.billingString = `$${convertToCurrency(invoice.amount_due)}`;
      billing.date = invoice.amount_due;
      billing.quantity = invoice.lines.total_count;
      billing.periodEnd = invoice.period_end;
      billing.periodEndString = localDateTimeString(invoice.period_end)
    }
  
    console.log(`getUser:userData`, userData);

    const payed = await hasActiveSubscription(uid);
    console.log("getUserByUID:payed", payed);
    console.log("getUserByUID:org", org);

    setActiveSubscription(payed);
    let usersCampaigns = await getUserCampaigns(uid);
    const user = composeUser(admin.data(),billing)
    console.log(`getUserByUID:usersCampaigns`, usersCampaigns);
    setUser({
      ...user
    });

    setCampaigns(usersCampaigns);
    return;
  }
  async function refreshAmountDue(){
    const invoice = await getInvoice(user.stripeId);
    const billingString = `$${convertToCurrency(invoice.amount_due)}`;
    const billing = {...user.billing,amountDue:invoice.amount_due,billingString:billingString}
    console.log('refreshAmountDue:billing',billing)
    setUser({...user,billing:{...billing}})
  }
  function composeUser(userData,billing){
    
    console.log('composeUser', JSON.stringify(billing))
   if(billing.amountDue >= 0){
    const billingAccess = {billingAccess:true}
    return ({...userData,...billingAccess,billing})
   } else {
    const billingAccess = {billingAccess:false}
    return ({...userData,...billingAccess})
   }
  }
  // Anytime the user create there own account they are by defualt an admin
  function buildNewUserObject(uid, email) {
    const data = {
      uid: uid,
      email: email,
      campaignIds: [],
      role:"admin"
    };
    console.log("buildNewUserObject", JSON.stringify(data));
    setActiveSubscription(false);
    setUser({
      ...data,
    });
    setCampaigns([]);
    return data;
  }
  function updateCampaign(campaign) {
    console.log(`updateCampaign:user:`, user);
    console.log(`updateCampaign:currentUser:`, currentUser);

    // orginal
    let campaignsArray = campaigns;
    // the campaign that needs to be updated
    let campaignList = campaignsArray.filter((c) => c.id == campaign.id);

    // the needed update to the campaign
    if (campaignList.length) {
      // orginal without the campaign
      campaignsArray = campaignsArray.filter((c) => c.id != campaign.id);
      let newCampaign = { ...campaignList[0], ...campaign };
      // add updated campaing back to user list
      campaignsArray.push(newCampaign);
      // create a new user object

      // call hook responsible for updating the user
      setCampaigns(campaignsArray);
    }
  }
  function resetCampaignStats(campaign) {
    console.log(`updateCampaignDetails:campaign`, campaign);
    let copy = [...campaigns];
    const index = campaigns.findIndex((element) => element.id === campaign.id);
    console.log(`updateCampaignDetails:index`, index);

    if (index != -1) {
      copy[index].clicks = 0;
      copy[index].times = [];
      // copy[index].affiliateArray = [
      //   { clicks: 0, times: [], source: "Link" },
      //   { times: [], clicks: 0, source: "QR" },
      // ];
    }
    setCampaigns(copy);
  }

  function updateCampaignDetails(campaign) {
    console.log(`updateCampaignDetails:campaign`, campaign);
    let copy = [...campaigns];
    const index = copy.findIndex((element) => element.id === campaign.id);
    console.log(`updateCampaignDetails:index`, index);
    if (index != -1) {
      copy[index].description = campaign.description;
      copy[index].title = campaign.title;
      copy[index].url = campaign.url;
      copy[index].status = campaign.status;
      copy[index].qrBGColor = campaign.qrBGColor;
      copy[index].qrFGColor = campaign.qrFGColor;
      copy[index].facebookId = campaign.facebookId;
      copy[index].googleId = campaign.googleId;
    }
    setCampaigns(copy);
  }

  function logout() {
    setUser({});
    setOrg({});
    setCampaigns([]);
    setPremiumStatus(false);
    setLoggedIn(false);
    return auth.signOut();
  }

  function resetPassword(email) {
    console.log(`email ---------------- ${email}`);
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  async function photoUploadBackend(file) {
    var metadata = {
      contentType: file.type,
    };
    console.log(`photoUploadBackend`);
    // Upload file and metadata to the object 'images/mountains.jpg'
    var uploadTask = storageRef
      .child("images/" + currentUser.uid)
      .put(file, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...
          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        // And update user database to use new url
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log("File available at", downloadURL);
          const newuser = {
            bio: user.bio,
            links: user.links,
            photoUrl: downloadURL,
            username: user.username,
          };

          db.collection("admin").doc(currentUser.uid).update(newuser);
          console.log(`user: ${JSON.stringify(newuser)}`);
          setUser(newuser);
        });
      }
    );
  }

  function verifyEmail() {
    return auth.currentUser.sendEmailVerification();
  }
  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      console.log(`useEffect:currentUser`, JSON.stringify(currentUser));
      setCurrentUser(currentUser);
      // const premium = await isUserPremium();
      // console.log('useEffect:premium',premium)
      // setPremiumStatus(premium);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (loggedIn !== null) {
      console.log("useEffect:update:memory:loggedIn", loggedIn);
      window.localStorage.setItem("count", loggedIn);
    }
  }, [loggedIn]);

  useEffect(() => {
    console.log("useEffect:update:memory:campaigns", campaigns);
    if (campaigns !== null && campaigns != undefined) {
      window.localStorage.setItem("campaigns", JSON.stringify(campaigns));
    }
  }, [campaigns]);

  useEffect(() => {
    console.log("useEffect:update:memory:user", user);
    if (user !== null && user != undefined) {
      window.localStorage.setItem("user", JSON.stringify(user));
    }
  }, [user]);

  useEffect(() => {
    console.log("useEffect:update:memory:user", user);
    if (org !== null && org != undefined) {
      window.localStorage.setItem("org", JSON.stringify(org));
    }
  }, [org]);
  useEffect(() => {
    console.log(
      "useEffect:update:memory:activeSubscription",
      activeSubscription
    );
    if (activeSubscription !== null && activeSubscription != undefined) {
      window.localStorage.setItem(
        "activeSubscription",
        JSON.stringify(activeSubscription)
      );
    }
  }, [activeSubscription]);

  // Recover state from a reload
  useEffect(() => {
    console.log("useEffect:recover:reload");
    const storedLoggedIn = window.localStorage.getItem("count");
    const storedCampaigns = window.localStorage.getItem("campaigns");
    const storedUser = window.localStorage.getItem("user");
    const storedOrg = window.localStorage.getItem("org");
    console.log('storedOrg',storedOrg)

    const storedactiveSubscription =
      window.localStorage.getItem("activeSubscription");
    console.log("useEffect:recover:localStorage,", storedLoggedIn);
    console.log("useEffect:recover:storedCampaigns,", storedCampaigns);

    if (storedCampaigns != "undefined" && storedCampaigns != "null") {
      console.log("useEffect:recover:update:campaigns", campaigns);
      setCampaigns(JSON.parse(storedCampaigns));
    }
    if (storedOrg != "undefined" && storedOrg != "null") {
      console.log("useEffect:recover:update:storedOrg", storedOrg);
      setOrg(JSON.parse(storedOrg));
    }
    if (storedUser != "undefined" && storedUser != "null") {
      console.log("useEffect:recover:update:user", user);
      setUser(JSON.parse(storedUser));
    }
  
    if (
      storedactiveSubscription != "undefined" &&
      storedactiveSubscription != "null"
    ) {
      setActiveSubscription(JSON.parse(storedactiveSubscription));
    }
    setLoggedIn(JSON.parse(storedLoggedIn));
  }, []);

  const value = {
    setUser,
    setCampaigns,
    campaigns,
    verifyEmail,
    currentUser,
    user,
    signupWithEmail,
    login,
    logout,
    resetPassword,
    updatePassword,
    updateEmail,
    photoUploadBackend,
    setCurrentUser,
    updateCampaign,
    resetCampaignStats,
    updateCampaignDetails,
    getUserByUID,
    setLoggedIn,
    loggedIn,
    premiumStatus,
    pullUpdatesToUserCampagins,
    activeSubscription,
    googleOAuthSignInOrSignUp,
    selectedPlan,
    autoNavPlans,
    org,
    setOrg,
    refreshAmountDue
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
