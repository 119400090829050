import { useState} from 'react';
import { Button } from "primereact/button";
import { InputText } from 'primereact/inputtext';
import { Card } from "primereact/card";
import { useAuth } from "../../contexts/AuthContext";
import 'primeicons/primeicons.css';
import { updateOrg } from '../../data/OrgService';

const styles = {
  title: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "5px",
    marginTop:-15,
    marginRight:'5%',
    fontSize: "1.5rem",
    fontWeight: "700"
  },
  tag : {
    borderStyle: 'solid',
    borderWidth: '1.5px',
    borderRadius: '5px',
    borderColor: '#dadada',
    padding: '2.5px 7.5px',
    display: 'inline-block',
    '&:hover' : {
      backgroundColor: '#efefef'
    }
  },

}

export default function MarketingCard(props){
    const {user,org,setOrg}=  useAuth();

    console.log('MarketingCard:user.orgId',user.orgId)
    console.log('MarketingCard:org',org)

    const [editing, setEditing] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const [googleId, setGoogleId] = useState(org.googleId)
    const [facebookId, setFacebookId] = useState(org.facebookId)
  
    const handleSave = () => {
      setEditing(false);
      setCanSubmit(false);
      const update = {googleId:googleId,facebookId:facebookId}
      console.log('new google Id', googleId);
      console.log('new facebook id', facebookId);
      updateOrg(user.orgId,update)
      setOrg(...org,...update)
    }
  
    return(
      <Card style={{wordWrap: 'break-word'}}>
        <div style={styles.title}>
          Tags
        </div>
         
        <div>Your uploaded analytics tags will appear on all your members' QR codes. </div>
        <div style={{ marginBottom:'20px'}}>Using these, you can retarget anyone who scans, across all your members.</div>
        <div style={{marginBottom:'5px'}}>
          Google Tag <InputText  value={googleId} onChange={(e)=>{setCanSubmit(true); setGoogleId(e.target.value)}} disabled={!editing} style={styles.tag} />
        </div>
        <div  style={{marginBottom:'5px'}}>
          Facebook Tag <InputText value={facebookId} onChange={(e)=>{setCanSubmit(true); setFacebookId(e.target.value)}} disabled={!editing} style={styles.tag}/>
          </div>
  
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop:'30px', marginBottom:'-10px' }}>
          {!editing ? 
            <Button label="Edit" size="small" style={{ marginRight: "20px",height:40, }} icon={'pi pi-pencil'} onClick={()=>{setEditing(true)}}/>
          :
          <>
            <Button label="Cancel" className="p-button-danger" size="small" style={{ marginRight: "10px",height:40,}} onClick={()=>{setCanSubmit(false); setEditing(false); setGoogleId(props.googleId); setFacebookId(props.facebookId)}}/>
            <Button label="Save" size="small" disabled={!canSubmit} style={{ marginRight: "20px",height:40, }} onClick={handleSave}/>
          </>
        }
          </div>
      </Card>
    )
  }
  