
import { useEffect, useRef, useState } from "react";
import { getDailyBuckets } from "../../utils/dateUtils";
import { Card } from "primereact/card";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
export function HitsBarChart({times}){

    const [bucketData,reBucket] =  useState([])
    useEffect(()=>{
    const data = getDailyBuckets(times)
    reBucket(data)
   },[times])
  
    // Create an empty object to hold the data for each day
  
    console.log(bucketData)
    return(
  <>
      <div
        style={{
          marginTop:"40px",
          fontSize: "1.5rem",
          fontWeight: "700",
          justifySelf: "left",
          marginBottom:'5px'
        }}
      >
        Data
      </div>
      <div style={{width:'100%', height:'400px'}}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={bucketData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="day" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="visits" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
      </div>
      </>
    )
  }