import {  useState } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import TerminateModal from "./TerminateModal";
import EditModal from './EditModal'
import {update} from '../../../data/CampaignService'
import { useAuth } from "../../../contexts/AuthContext";
import {style} from '../CardStyles';
import { COLLECTION } from "../../../constants/Text"; 
const colors = {
  Active: "green",
  Ended: "red",
  Terminated: "blue"
};

export default function ManageDetailsCard(props) {
  console.log('ManageDetailsCard:props',JSON.stringify(props))
  const {updateCampaignDetails} = useAuth();
  const [terminateModalVisible, setTerminateModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [campaignState,UpdateState] = useState(props);
  const updateDetails = (value) => {
    // create a new reference 
    console.log('ManageDetailsCard:props',props)
    console.log('ManageDetailsCard:update:value',value)
    let theUpdate = {...campaignState,...value}
    console.log('ManageDetailsCard:update:theUpdate',theUpdate)
    UpdateState(theUpdate);
    const updates = {
      description:value.description,
      title:value.title,
      url:value.url,
      googleId:value.googleId,
      facebook:value.facebookId,
    }
    update(COLLECTION.CAMPAIGNS,props.id,updates)
    updateCampaignDetails(theUpdate);
    setEditModalVisible(false)
  }
  
  return (
    <Card style={{wordWrap: 'break-word'}}>
   
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "5px",
          marginTop:-15,
          marginRight:'5%'
        }}
      >
        <span style={{ fontSize: "1.5rem", fontWeight: "700" }}>
          {campaignState.title}
        </span>
       
      </div>

      <div
      style={{width:'100%'}}
      >
        {campaignState.description}
      </div>



      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          label="Edit"
          size="small"
          
          style={{ marginRight: "20px",height:40,fontSize:12 }}
          onClick={() => {
            console.log("edit campaing button");
            setEditModalVisible(true);
          }}
        />

      </div>
      <EditModal
        visible={editModalVisible}
        state= {campaignState}
        setVisible={setEditModalVisible}
        attachments={props.attachments}
        updateState={updateDetails}
      />
    </Card>
  );
}