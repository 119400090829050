// @ts-nocheck
import { db } from "../firebase";
import firebase from "firebase";
import { Communication } from "./models/UserModel";
import {COLLECTION} from "../constants/Text";
import { Affiliate, Campaign } from "./models/CampaignModels";

export async function update(collection:string,doc:string,value) {
  try{
    console.log('CampaignService:update:doc',doc)
    console.log('CampaignService:update:value',value)

    await db.collection(collection).doc(doc).update(value);

  } catch(error){
      console.log(`firebase update failed with error`,error)
  }
}
export async function addAllowedId(campaignId, uid) {
  return db
    .collection(COLLECTION.CAMPAIGNS)
    .doc(campaignId)
    .update({
      allowedAffiliateIds: firebase.firestore.FieldValue.arrayUnion(uid),
    });
}

export async function getCampaign(campaignId){
  return await (await db.collection(COLLECTION.CAMPAIGNS).doc(campaignId).get()).data()
}

export async function addQR(campaignId) {
  try {
    const docRef = db.collection(COLLECTION.CAMPAIGNS).doc(campaignId);
    const subCollectionRef = docRef.collection(COLLECTION.AFFILIATES);
    
    const newDocRef = await subCollectionRef.add({
      source: "QR",
      clicks: 0,
      times: [],
    });

    console.log('Created campaign QR doc', newDocRef);
    await addAllowedId(campaignId, newDocRef.id);
    
    console.log('docRef ID', newDocRef.id);
    await db.collection(COLLECTION.CAMPAIGNS).doc(campaignId).update({ QR: newDocRef.id });

    return newDocRef.id;
  } catch (error) {
    console.error("addQR Error creating document: ", error);
    return '';
  }
}


export async function addLink(campaignId) {
  try {
    const docRef = db.collection(COLLECTION.CAMPAIGNS).doc(campaignId);
    const subCollectionRef = docRef.collection(COLLECTION.AFFILIATES);
    
    const newDocRef = await subCollectionRef.add({
      source: "Link",
      clicks: 0,
      times: [],
    });

    console.log('Created campaign Link doc', newDocRef);
    await addAllowedId(campaignId, newDocRef.id);
    
    console.log('docRef ID', newDocRef.id);
    await db.collection(COLLECTION.CAMPAIGNS).doc(campaignId).update({ link: newDocRef.id });
  } catch (error) {
    console.error("addLink Error creating document: ", error);
  }
}


export async function addAffiliateCollection(campaignId, affiliate, status) {
  console.log("addAffiliateCollection",campaignId, affiliate, status)
  const docRef = db.collection(COLLECTION.CAMPAIGNS).doc(campaignId);
  const subCollectionRef = docRef.collection(COLLECTION.AFFILIATES);
  const subDocRef = subCollectionRef.doc(affiliate.uid);
  subDocRef
    .set({
      status: status,
      email: affiliate.email,
      totalClicks: 0,
      times: [],
    })
    .then(() => {
      console.log("set addAffiliateCollection Document updated successfully");
    })
    .catch((error) => {
      console.error("addAffiliateCollection Error creating document: ", error);
    });


}
export async function addAffiliateTempCollection(
  campaignId,
  affiliateEmail,
  status
) {
  const docRef = db.collection(COLLECTION.CAMPAIGNS).doc(campaignId);
  const subCollectionRef = docRef.collection(COLLECTION.AFFILIATES);
  const subDocRef = subCollectionRef.doc(affiliateEmail);
  subDocRef
    .set({
      status: status,
      email: affiliateEmail,
      totalClicks: 0,
      times: [],
    })
    .then(() => {
      console.log("set addAffiliateCollection Document updated successfully");
    })
    .catch((error) => {
      console.error("addAffiliateCollection Error creating document: ", error);
    });
}
export async function addNewCampaign(campaign, uid) {
  console.log("addNewCampaign:campaign", campaign);
  var id = '';
  var qr = '';
  
  try {
    console.log('addNewCampaign:try:add campaign')
    const docRef = await db.collection(COLLECTION.CAMPAIGNS).add(campaign);
    console.log("addNewPromotion:docRef.id", docRef.id);
    id = docRef.id;
    campaign.id = id;
    
    await db.collection(COLLECTION.CAMPAIGNS).doc(id).update({ id: id });
    await db.collection(COLLECTION.ADMIN)
      .doc(uid)
      .update({
        campaignIds: firebase.firestore.FieldValue.arrayUnion(id),
      });
    
    // await addQR(id);
    // await addLink(id);
    
    const campaignData = await db.collection(COLLECTION.CAMPAIGNS).doc(id).get();
    console.log('addNewCampaign:finally', campaignData.data());
    
    return campaignData.data();
  } catch (error) {
    // Handle the error here
    console.error("Error adding campaign:", error);
    throw error;
  }
}


export async function terminateStatus(campaignId){
  const docRef = db.collection(COLLECTION.CAMPAIGNS).doc(campaignId);
}

export async function getAllAffiliatesOnCampaign(campaign){
  let subCollectionDocs = [];
  console.log(`getAllAffiliatesOnCampaign:campaignId`,campaign.id)

  const docRef = db.collection(COLLECTION.CAMPAIGNS).doc(campaign.id);
  const subCollectionRef = docRef.collection(COLLECTION.AFFILIATES);
  let querySnapshot = await subCollectionRef.get();

  // Get all the documents in the sub-collection
    console.log('querySnapshot',querySnapshot)
    querySnapshot.forEach((doc) => {
      console.log(`getAllAffiliatesOnCampaign:affiliate`,doc.data())
      // Push each document into the array
       subCollectionDocs.push(doc.data());
  })
    // Use the array of documents here
  campaign.affiliateArray =  subCollectionDocs;
 
}
export async function clearQRStats(campaign:Campaign){
  console.log(`clearQRStats:campaignId`,campaign.id)

  const docRef = db.collection(COLLECTION.CAMPAIGNS).doc(campaign.id);
  docRef.update({
    clicks:0,
    times:[]
  })
//   const subCollectionRef = docRef.collection(COLLECTION.AFFILIATES);
//   let querySnapshot = await subCollectionRef.get();
//   querySnapshot.forEach((doc) => {
//     doc.ref.update({
//       clicks:0,
//       times:[]
//     })
// })

}

export async function collectAffiliates(campaigns){
  let promises = [];
  campaigns.forEach(campaign => {
    console.log('collectAffiliates:campaign',campaign)
    promises.push( getAllAffiliatesOnCampaign(campaign))
  })
  await Promise.all(promises)
}
export async function getAllUserCampaigns(uid){
  console.log(`getAllUserCampaigns:uid`,uid)
  const docRef = await db.collection(COLLECTION.ADMIN).doc(uid).get();
  const userData = docRef.data();
  let campaigns = await getCampaigns(userData.campaignIds)
  return campaigns;
}

export async function getCampaigns(campaignIds){
  console.log(`getCampaigns:campaignIds, ${campaignIds}`)
  let promises = []
  campaignIds.forEach(id =>{
    promises.push(db.collection(COLLECTION.CAMPAIGNS).doc(id).get())
  })
  let responses = await Promise.all(promises);
  let campaigns = [];

  responses.forEach((campaign) => {
    console.log("getUser:affiliatedCampaignArray:campaign.data()", campaign.data());
    campaigns.push(campaign.data());
  });
  console.log(`getCampaigns:campaigns, ${campaigns}`)

  return campaigns
}
