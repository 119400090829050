import DataRenderer from '../DataRender/DataRenderer';
import { Button } from 'primereact/button';
import { useWindowSize} from '@react-hook/window-size'

function Header(props){
   
        return(
        <div style={styles.headerRow}>
         <h1>{props.title}</h1>
         {
            props.subheader ? 
                <h2>{props.subheader}</h2>
             : null
         }
         { 
            props.buttonTitle ?        
                <Button onClick={props.buttonHandler} icon={props.buttonIcon} label={props.buttonTitle===' '?'':props.buttonTitle}  style={{marginTop:'10px',height:'40px'}}/>
            : null 
         }

        </div> 
        )
   
    
 
}

function Panel (props){
    const [width, height] = useWindowSize()


    return(
    <div style = {{marginLeft:'5%',marginRight:'5%',maxWidth:'1250px'}}>
    <Header {...props}/>
     {  props.data && props.data.length ?
        <DataRenderer data = {props.data }  component = {props.component}/>:
        <h5 style={styles.emptyMessage}>{props.emptyMessage}</h5> 
        }
  </div>
  )
    
 }
 const styles = {
    emptyMessage:{
        paddingLeft:'20px'
    },
    headerRow:{
        display: 'flex',
        marginBottom:'10px',
        marginTop:'25px',
        justifyContent: 'space-between',
        alignContent:'center',
        // backgroundColor:'white',
        height:'60px',
        alignItems: 'center'

    }
}
export default Panel;