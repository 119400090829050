import React, { useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { getSpecialProduct } from "../data/ProductService";
import {checkoutPlan} from "../stripe/createCheckoutSession"
import { useAuth } from "../contexts/AuthContext";
import { PLANS } from "../constants/Plans";
import LoadingUtil from "../components/LoadingDialog/LoadingUtil";
import ToastUtil from "../components/ToastUtil/ToastUtil";
import { useHistory } from 'react-router-dom';
import Route from "./Route";
import { auth } from "../firebase";
export default function AccountPlansScreen (){
  const history = useHistory();

  const [coupon,updateCoupon]= useState("")
  const {user,currentUser,loggedIn,autoNavPlans } = useAuth();

  // render() {
    return (
      <>
        <div className="grid grid-nogutter surface-0 text-800">
          <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
            <section>
              <span
                className="block text-6xl font-bold mb-1"
                style={{ lineHeight: 1 }}
              >
                Marketing For Real Estate Professionals
              </span>
              <div
                className="text-6xl text-primary font-bold mb-3"
                style={{ marginTop: "20px", lineHeight: 1 }}
              >
                Generate Leads
              </div>
              <p className="mt-0 mb-4 text-700 line-height-3">
              Build Audiences From Open House & For Sale Signs              </p>

              <Button
                label="Learn More"
                type="button"
                className="mr-3 p-button-raised"
                onClick={()=>{
                  window.open("https://qrhome.xyz", '_blank');
               }}
              />
              <Button
                onClick={()=>{
                  window.open("https://calendly.com/qrhome/live-demo", '_blank');
               }}
                label="Live Demo"
                type="button"
                className="p-button-outlined"
              />
            </section>
          </div>
          <div className="col-12 md:col-6 overflow-hidden">
            {/* <img
              src="https://www.primefaces.org/primeblocks-react/assets/images/blocks/hero/hero-1.png"
              alt="hero-1"
              className="md:ml-auto block md:h-full"
              style={{ clipPath: "polygon(8% 0, 100% 0%, 100% 100%, 0 100%)" }}
            /> */}
          </div>
        </div>



        <div className="surface-0" style={{  }}>
          <div className="text-900 font-bold text-6xl mb-4 text-center">
            Pricing Plans
          </div>
          <div className="text-700 text-xl mb-6 text-center line-height-3">
            Affordable, flexible, and scalable for any business size
          </div>

          <div className="grid">
            
            <div className="col-12 lg:col-4">
              <div className="p-3 h-full">
                <div
                  className="shadow-2 p-3 h-full flex flex-column"
                  style={{ borderRadius: "6px" }}
                >
                  <div className="text-900 font-medium text-xl mb-2">Monthly Plan</div>
                  <div className="text-600">No Commitment Cancel Anytime</div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">$14.99</span>
                    <span className="ml-2 font-medium text-600">per month</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <ul className="list-none p-0 m-0 flex-grow-1">
                  <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>15 Dynamic QRs</span>
                    </li>
                  <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>First 30 Days Free</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Google tracking, analytics, geolocations, and readvertise</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Facebook tracking and readvertise</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Visit Counts</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Customizable</span>
                    </li>
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                  <Button label="Buy Now" className="p-3 w-full mt-auto"
                  onClick={()=>{
                    console.log('buy now loggedIn', loggedIn)
                    console.log('current user buy now', currentUser)
                    console.log('current user buy now', JSON.stringify(currentUser))
                    console.log('current user buy now', JSON.stringify(currentUser))
                    if(loggedIn){
                      LoadingUtil.show()
                      checkoutPlan(user.uid,PLANS.MONTHLY)
                    } else { 
                      ToastUtil.showMessage("info","Create an Account or Login","You'll need to create an account")
                      autoNavPlans(PLANS.MONTHLY);
                      history.push("/signup"); // Replace '/new-route' with the desired route path
                    }
                  }}
                   />
                </div>
              </div>
            </div>

            <div className="col-12 lg:col-4">
              <div className="p-3 h-full">
                <div
                  className="shadow-2 p-3 h-full flex flex-column"
                  style={{ borderRadius: "6px" }}
                >
                  <div className="text-900 font-medium text-xl mb-2">
                    Loyalty Plan
                  </div>
                  <div className="text-600">Save Money & Pay Anually</div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">$164.99</span>
                    <span className="ml-2 font-medium text-600">anually</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <ul className="list-none p-0 m-0 flex-grow-1">
                  <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>15 Dynamic QRs</span>
                    </li>
                  <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Google Tracking, Analytics, Geolocations, and Re-advertise</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Facebook Tracking and Re-advertise</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Visit Counts</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Customizable</span>
                    </li>
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <Button label="Buy Now" className="p-3 w-full"
                   onClick={()=>{
                    if(loggedIn){
                      LoadingUtil.show()
                      checkoutPlan(user.uid,PLANS.ANUAL)
                    } else { 
                      ToastUtil.showMessage("info","Create an Account or Login","You'll need to create an account")
                      autoNavPlans(PLANS.ANUAL);
                      history.push("/signup"); // Replace '/new-route' with the desired route path
                    }
                  }}
                   />
                </div>
              </div>
            </div>
            <div className="col-12 lg:col-4">
              <div className="p-3 h-full">
                <div
                  className="shadow-2 p-3 h-full flex flex-column"
                  style={{ borderRadius: "6px" }}
                >
                  <div className="text-900 font-medium text-xl mb-2">
                  Enterprise Plan
                  </div>
                  <div className="text-600">For Organizations & Teams</div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">$49.99</span>
                    <span className="ml-2 font-medium text-600">Org Fee</span>
                  </div>
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900"> + $9.99</span>
                    <span className="ml-2 font-medium text-600">per user</span>
                  </div>
                  <div className="flex align-items-center">
                    <span className="ml-2 font-medium text-600">per month</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <ul className="list-none p-0 m-0 flex-grow-1">
                  
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Admin Control Over Marketing Tags</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Discounted Accounts</span>
                    </li>
                    
                  <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>24/7 Support</span>
                    </li>
                         
                  <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Staff Trainings</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Unlimited Admin Accounts</span>
                    </li>
                   
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <Button
                    label="Buy Now"
                    className="p-3 w-full"
                    onClick={()=>{
                      console.log('buy now loggedIn', loggedIn)
                      console.log('current user buy now', currentUser)
                      console.log('current user buy now', JSON.stringify(currentUser))
                      console.log('current user buy now', JSON.stringify(currentUser))
                      if(loggedIn){
                        LoadingUtil.show()
                        try{
                          checkoutPlan(user.uid,PLANS.ENTERPRISE)
                        }catch(error){
                          console.log(error)
                        }
                      } else { 
                        ToastUtil.showMessage("info","Create an Account or Login","You'll need to create an account")
                        autoNavPlans(PLANS.ENTERPRISE);
                        history.push("/signup"); // Replace '/new-route' with the desired route path
                      }
                    }}
                  
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

