export function getDailyBuckets(timestamps){
    // Create an empty object to hold the data for each day
    const visitsByDay = timestamps.reduce((acc, timestamp) => {
      // Convert the timestamp to a date object
      const date = new Date(timestamp);
    
      // Get the date in the format 'M/D/YY'
      const day = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().slice(-2)}`;
    
      // Find the entry in the accumulator array for this day
      const entry = acc.find((item) => item.day === day);
    
      if (entry) {
        // If an entry exists, increment the visits count
        entry.visits++;
      } else {
        // If an entry doesn't exist, add a new entry with a visits count of 1
        acc.push({ day, visits: 1 });
      }
    
      return acc;
    }, []);
    
    // Sort the array by date in ascending order
    visitsByDay.sort((a, b) => {
        const dateA = new Date(a.day);
        const dateB = new Date(b.day);
        return dateA - dateB;
    });

    return visitsByDay;
}
