import React, { Component } from "react";
import {AiOutlineMail} from 'react-icons/ai';
import {AiOutlinePhone} from 'react-icons/ai';
import Icon from "../../assets/images/qrhomeicon.svg";
import "./FooterStyles.css";


import { AiOutlineTwitter } from "react-icons/ai";
import {AiOutlineInstagram} from "react-icons/ai";
import {FaFacebookF} from 'react-icons/fa';
import {FaLinkedin} from 'react-icons/fa';
import {FaTiktok} from 'react-icons/fa';

import PropTypes from "prop-types";
interface Props {
  backgroundColor?: string;
}



export default class Footer extends Component<Props> {
  static defaultProps: Partial<Props> = {
    backgroundColor: "white",
  };
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={{padding:'0% 5%', display:'inline-block', width:'100%'}}>
        
        <div style={{height:"1px", width:"100%", backgroundColor:"#6366f1", opacity:"0.25"}}/>

          
          <div style={{}}>
            <div style={{ flex:"1 0 0", textDecoration: "none", fontSize:"3rem", margin: "20px 20px 0px 20px"}} className="navbar-brand logo-image Logo">
              <img src={Icon} width="60px" style={{marginTop:"-5px",marginLeft:'-6px'}}/>
              <text > qrhome</text>
            </div>
            <div style={{flex:"1 0 0", textAlign:"left", display:"flex", flexDirection:"column", alignItems:"flex-start", color:'#d9e3ea'}}>
              <div style={{ textDecoration: "none", fontSize: '1.25rem', margin: "0px 20px"}}> We provide realtors with tools to market effectively.</div>
              <div style={{ textDecoration: "none", fontSize: '1.25rem', margin: "0px 20px"}}> Join now to gain an analytical edge over the competition.</div>          
            </div>

          </div>

          <div style={{margin:'30px 20px 10px 20px', display:'flex', flexWrap:'wrap', justifyContent: 'space-between', alignItems:'center'}}>
            
           
              <div style={{ display:"flex", justifyContent:'center', }}>
                <text style={{color:'#d9e3ea', fontSize: '1.25rem'}}>© Saguaro Tech Inc. All rights reserved.</text>
              </div>

              
              <div style={{ display:"flex", alignItems:'flex-end', margin:'10px 0px 5px 0px'}}>
                <a className="socialIcon" target="_blank" href="https://twitter.com/qrhomexyz">
                  <AiOutlineTwitter size={13} color="white"/>
                </a>
                <a className="socialIcon" target="_blank" href="https://www.tiktok.com/@qrhome.xyz">
                  <FaTiktok size={13} color="white"/>
                </a>
                <a className="socialIcon" target="_blank" href="https://www.facebook.com/qrhomexyz">
                  <FaFacebookF size={13} color="white"/>
                </a>
                <a className="socialIcon" target="_blank" href="https://www.instagram.com/qrhome.xyz/">
                  <AiOutlineInstagram size={13} color="white"/>
                </a>
                <a className="socialIcon" target="_blank" href="https://www.linkedin.com/in/qrhome-xyz">
                  <FaLinkedin size={13} color="white"/>
                </a>
              </div>
          </div>
      </div>
    );
  }
}
