import { useEffect,useState } from 'react'
import {Route,Redirect} from 'react-router-dom';
import {useAuth} from './contexts/AuthContext';

export default function PrivateRoute({component:Comp,path,...rest}) {
    const {currentUser,user,loggedIn,campaigns} = useAuth();
    const [localLoggedIn,updateLoggendIn]= useState(loggedIn)
    console.log('PrivateRoute:currentUser',JSON.stringify(currentUser))
    console.log('PrivateRoute:loggedIn',JSON.stringify(loggedIn))
    console.log('PrivateRoute:user',JSON.stringify(user))
    console.log('PrivateRoute:campaigns',JSON.stringify(campaigns))
    var isLoading = loggedIn === null
    useEffect(()=>{
        console.log('PrivateRoute:useEffect:loggedIn',loggedIn)
        console.log('PrivateRoute:useEffect:localLoggedIn',localLoggedIn)
        isLoading = loggedIn === null

    },[loggedIn])

    return (
        <Route
            {...rest}
            render = { props => {
                //    return currentUser ? <Component {...props} /> : <Redirect to = './signin' />
                return loggedIn ? <Comp {...props} /> : (isLoading ? " " : <Redirect to="/" />)
                }}
        ></Route>
    )
}
