import { useState } from "react";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { style } from "./CardStyles";
import TrackingSection from "./TrackingSection";

export default function DetailsCard(props) {
  // const { activeSubscription } = useAuth();

  console.log("DetailsCard:props",JSON.stringify(props))
  return (
    <Card style={style.formCard}>
      <div style={{ marginTop: -10 }}>
        <div style={{ display: "inline-block" }}>
          <div style={{ fontSize: "30px", fontWeight: "700", lineHeight: 1 }}>
            QR Details
          </div>
          <div
            style={{ fontSize: "12px", color: "#9e9b9b", marginLeft: "5px" }}
          >
            (required)
          </div>
        </div>
      </div>

      <h4>Name/Address</h4>
      <InputText
        style={style.cardInput}
        value={props.state.title}
        onChange={(e) => {
          props.update({ title: e.target.value });
        }}
      />

      <h4>Url</h4>
      <InputText
        value={props.state.url}
        style={style.cardInput}
        onChange={(e) => {
          props.update({ url: e.target.value });
        }}
        cols={30}
      />

      <h4>Description</h4>
      <InputTextarea
        value={props.state.description}
        onChange={(e) => {
          console.log("updateDescription");
          // setDescription(e.target.value)
          props.update({ description: e.target.value });
        }}
        rows={5}
        style={{ width: "95%" }}
      />
    <TrackingSection update={props.update} state={props.state}/>
    </Card>
  );
}
