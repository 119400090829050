import {useEffect, useState} from 'react';
import Icon from "../../assets/images/qrhomeicon.svg";
import { Link } from "react-router-dom";
import { Button } from 'primereact/button';
import { useWindowSize} from '@react-hook/window-size'
import Hamburger from 'hamburger-react'
import "./NavbarStyles.css";
import {useAuth} from '../../contexts/AuthContext';

export default function Navbar({bgColor}) {
  const [width, height] = useWindowSize()
  const [isOpen, setOpen] = useState(false)
  const {loggedIn,logout} = useAuth();
  const DropDownOptions = ()=>{
    return loggedIn ? <div style={{color:'#d9e3ea', backgroundColor: bgColor, textAlign:'center',}}>
    <Link className="textStyleLink" to="/dashboard" >
      <h3 className='navbarTextStyle' style={{marginBottom:'10px'}}>Dashboard</h3>
    </Link>
      <h3 onClick={async ()=>{await logout();}} className='navbarTextStyle'> Logout </h3>
  </div>:
    
    <div style={{color:'#d9e3ea', backgroundColor: bgColor, textAlign:'center',}}>
    <Link className="textStyleLink" to="/signup" >
      <h3 className='navbarTextStyle' style={{marginBottom:'10px'}}>Sign up</h3>
    </Link>
    <Link className="textStyleLink" to="/signin">
      <h3 className='navbarTextStyle'> Login </h3>
    </Link>
  </div>

  }
  const BarOptions = () =>{
   return loggedIn ? <>
    {/* <Text  onClick={()=>{}}> */}
      <text className="textStyle" onClick={async ()=>{await logout();}}>Sign out</text>
    {/* </Text> */}
    <Link to="/dashboard">
      <Button style={{borderRadius:"0px", color: "#d9e3ea"}}  label="Dashboard" />
    </Link>
  </>: <>
    <Link className="textStyleLink" to="/signup">
      <text className="textStyle">Sign up</text>
    </Link>
    <Link to="/signin">
      <Button style={{borderRadius:"0px", color: "#d9e3ea"}}  label="Sign in" />
    </Link>
  </>
  

  }
  useEffect(()=>{},[loggedIn])
  console.log(width)
  return (
   <div>
    <div style={{backgroundColor:bgColor, color:"#d9e3ea", padding:"2.5% 5%", display: "flex", justifyContent: "space-between", alignItems:"center"}}>
          <Link
            style={{ textDecoration: "none", fontSize: 30 }}
            className="navbar-brand logo-image"
            to="/"
          >
            <img src={Icon} width="60px" style={{marginTop:"-5px"}}/>
            <text className={"Logo"}> qrhome</text>
            
          </Link>

          <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"30px", backgroundColor: bgColor}}>
            {
            width > 500 ?
            <BarOptions/>
            :
            <Hamburger toggled={isOpen} toggle={setOpen}/>
            }
          </div>

        </div>
        {isOpen 
        ?
         <DropDownOptions/>
        : 
          null
        }
   </div>
  );
}
