import { loadStripe } from "@stripe/stripe-js";

const initializeStripe = async () => {
    let stripePromise = await loadStripe(
      "pk_live_51MxDePDgVKrv0qMbbIBoqRoJ7Ykb2JMLy48VkuHgWZoY4aYl16S4LX3fFMQ9vmcabp11RS6hfZpqn4FDVP8ukZNp003o4asAOD"
    );
  return stripePromise;
}
export default initializeStripe;

