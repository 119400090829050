import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
// import {Navbar} from '@pkwy/react'
import Navbar from "../../components/Navbar/Navbar";
import "firebase/auth";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useHistory, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Logo from "../../components/Logo";
import firebase from "firebase";
import { GoogleLogin } from "@react-oauth/google";
import ToastUtil from "../../components/ToastUtil/ToastUtil";
import TermsNotice from "../../components/TermsNotice/TermsNotice";
import LoadingUtil from "../../components/LoadingDialog/LoadingUtil";
import {checkoutPlan} from "../../stripe/createCheckoutSession"

export default function SignInScreen() {
  let summary = "Failed to sign in";
  let detail = "Incorrect email or password";
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login, getUserByUID, setLoggedIn, googleOAuthSignInOrSignUp,selectedPlan,autoNavPlans } =
    useAuth();
  const [error] = useState("");
  const [oAuthOn, setoAuthOn] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const history = useHistory();
  const location = useLocation();

  console.log("signinscreen:location.state:", location.state);

  const showMessage = (severity, summary, detail) => {
    toast.current.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 9000,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("SignInScreen:handleSubmit");

    setLoading(true);
    login(emailRef.current.value, passwordRef.current.value)
      .then(async (response) => {
        if (response && response.user.emailVerified) {
          await getUserByUID(response.user.uid);
          setLoggedIn(true);
          if(selectedPlan){
            LoadingUtil.show()
            checkoutPlan(response.user.uid,selectedPlan)
            autoNavPlans(null)
          } else {
            history.push("/dashboard");
          }
        } else {
          showMessage("error", summary, "Email is unverified");
        }
      })
      .catch((e) => {
        console.log("error", e);
        showMessage("error", summary, detail);
      });
    setLoading(false);
  }

  useEffect(() => {
    if (location.state && location.state.emailWasJustVerified) {
      showMessage("success", "Email Verified", "You can now sign in.");
    }
  }, []);

  return (
    <div>
      <Toast ref={toast} position="bottom-right" style={{ zIndex: 10 }} />
      {/* <Navbar LogoComp={Logo} bgColor="#5f63ba" handleLogoClick={()=>{history.push('/')}} handleSignUpClick={()=>{history.push('/signup');}} handleSignInClick={()=>{history.push('/signin');}}/> */}
      <Navbar bgColor="#5f63ba" />

      <header id="header" className="ex-2-header" style={{ padding: "1rem" }}>
        <h1>Login</h1>
        <p>
          You don't have an account? Then please{" "}
          <Link className="white" style={{ fontWeight: "bold" }} to="/signup">
            Sign Up
          </Link>
        </p>
        {/* <!-- Sign Up Form --> */}
        <div className="form-container" style={{ zIndex: 1 }}>
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              {/* <button type="button" class="login-with-google-btn" onClick={handleGoogleSignIn}>
                Sign in with Google
              </button> */}
              <GoogleLogin
                onSuccess={async (googleUser) => {
                  console.log("googleUser", JSON.stringify(googleUser));
                  const { credential } = googleUser;
                  try {
                    const googleCredential =
                      firebase.auth.GoogleAuthProvider.credential(credential);
                    const userCredential = await firebase
                      .auth()
                      .signInWithCredential(googleCredential);
                    const user = userCredential.user;
                    console.log("User signed up or signed in with Google:", user);
                    await googleOAuthSignInOrSignUp(user);
                    console.log("Successful Login");
                    setLoggedIn(true);
                    history.push("/dashboard");
                  } catch (error) {
                    // Handle error
                    ToastUtil.showError("Error signing in", error);
                    console.error(
                      "Error signing up or signing in with Google:",
                      error
                    );
                  }
                  // var userObject = jwt_decode(credentialResponse);
                  // console.log('userObject',JSON.stringify(userObject))
                  // console.log(`google oAuth`, JSON.stringify(userObject));
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            </div>
            <div style={{ margin: "12px 0px" }}>or</div>

            <div className="form-group">
              <input
                type="email"
                ref={emailRef}
                className="form-control-input notEmpty"
                id="lemail"
                required
              />
              {/* <label className="label-control" for="lemail">Email</label> */}
              <label className="label-control">Email</label>
              <div className="help-block with-errors"></div>
            </div>
            <div className="form-group">
              <input
                type="password"
                ref={passwordRef}
                className="form-control-input notEmpty"
                id="lpassword"
                required
              />
              {/* <label className="label-control" for="lpassword">Password</label> */}
              <label className="label-control">Password</label>
              <div className="help-block with-errors"></div>
            </div>

            <div
              className="form-group"
              style={{ textAlign: "right", lineHeight: "12px" }}
            >
              <Link
                className="white"
                style={{ color: "#5f63ba", fontSize: 12 }}
                to="/account-recovery"
              >
                Forgot Password?
              </Link>
            </div>
            <div className="form-group">
              <button
                disabled={loading}
                type="submit"
                className="form-control-submit-button"
                id="signup-button"
              >
                LOG IN
              </button>
            </div>

            <div style={{ fontSize: "9px", color: "grey" }}>
              By clicking Sign Up you agree to our{" "}
              <a href="/terms" style={{ color: "#5f63ba" }}>
                Terms and Conditions
              </a>{" "}
              and{" "}
              <a href="/terms" style={{ color: "#5f63ba" }}>
                Privacy Policy
              </a>
            </div>
          </form>
        </div>

     
      </header>
    </div>
  );
}
