import { Button } from 'primereact/button';

function Header(props){
    if(props.buttonTitle){
        return(
        <div style={styles.headerRow}>
         <h1>{props.panelTitle}</h1>
         <Button onClick={props.buttonHandler} icon={props.buttonIcon} label={props.buttonTitle}  style={{marginTop:'10px',height:'40px',}}/>
        </div> 
        )
    } else {
        return(
        <h1>{props.panelTitle}</h1>
        )
    }
    
 
}

function PanelSingleCard (props){
    return(
    <div style = {{marginLeft:'5%',marginRight:'5%',maxWidth:'1250px'}}>
    <Header {...props}/>
    <div style = {{marginBottom:'20px'}}>
    <props.component {...props}/>

    </div>
       
        
  </div>
  )
    
 }
 const styles = {
    emptyMessage:{
        paddingLeft:'20px'
    },
    headerRow:{
        display: 'flex',
        justifyContent: 'space-between',
        alignContent:'center',
        // backgroundColor:'white',
        height:'60px',
        alignItems: 'center'

    }
}
export default PanelSingleCard;