export function convertToCurrency(number) {
    // Convert to a decimal representation (e.g., 49.99)
    const currencyValue = (number / 100).toFixed(2);
  
    return currencyValue;
  }
export function localDateTimeString(number){
    // Convert the Unix timestamp to milliseconds
  const timestampInMilliseconds = number * 1000;
  const date = new Date(timestampInMilliseconds);

  // Extract individual date components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
  const day = String(date.getDate()).padStart(2, '0');

  // Format the date as "mm/dd/yyyy"
  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;

}