import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { createCheckoutSession } from "../../stripe/createCheckoutSession";
import { useAuth } from "../../contexts/AuthContext";
import Contact from "../../assets/images/contact.png";
import Upgrade from "../../assets/images/upgrade.png";
import { useWindowSize } from "@react-hook/window-size";
import LoadingUtil from "../LoadingDialog/LoadingUtil";

export default class DialogModal extends React.Component {
  // const [width, height] = useWindowSize()
  // const {logout,user,campaigns} = useAuth();
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      callToAction: false,
      customerServiceRequired: false,
      header: " ",
      body: " ",
      footer:true,
    };
  }

  handleYesClick = () => {
    this.setState({ visible: false });
    LoadingUtil.show();
    this.action();
    // createCheckoutSession(this.props.user.uid);
    // createCheckoutSessionV2()
  };
  showCallToAction = (header, body, action, subheading) => {
    this.action = action;
    this.setState({
      footer:true,
      visible: true,
      callToAction: true,
      customerServiceRequired: false,
      header: header,
      subheading: subheading,
      body: body,
    });
  };
  showCustomerServiceRequired = (header, body) => {
    console.log("header", header);
    this.setState({
      visible: true,
      footer:true,
      callToAction: false,
      customerServiceRequired: true,
      header: header,
      body: body,
    });
  };
  showAccountRestriction = (header, body) => {
    console.log("showAccountRestriction", header);
    this.setState({
      visible: true,
      callToAction: false,
      footer:false,
      customerServiceRequired: true,
      header: header,
      body: body,
    });
  };
  CallToAction = (props) => {
    return (
      <>
        {this.state.callToAction ? (
          <div className="card flex justify-content-center">
            <Dialog
              header={this.state.header}
              visible={true}
              // style={{ width: width < 500 ? "75vw" : "35vw" }}
              style={{ width: "75vw",maxWidth:500 }}
              onHide={() => this.setState({ callToAction: false })}
              footer={props.footerContent}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "30px",
                }}
              >
                <img src={Upgrade} style={{ maxWidth: "100px" }} />
              </div>
              <h6 className="m-0">{this.state.subheading}</h6>
              <br></br>
              <p className="m-0">{this.state.body}</p>
            </Dialog>
          </div>
        ) : null}
      </>
    );
  };
  CustomerService = ({ footerContentPremium }) => {
    console.log("state.Header", this.state.Header);

    return (
      <>
        {this.state.customerServiceRequired ? (
          <div className="card flex justify-content-center">
            <Dialog
              header={this.state.header}
              visible={true}
              // style={{ width: width < 500 ? "75vw" : "35vw" }}
              style={{ width: "75vw",maxWidth:500 }}
              onHide={() => this.setState({ customerServiceRequired: false })}
              footer={this.state.footer ? footerContentPremium: null}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "30px",
                }}
              >
                <img src={Contact} style={{ maxWidth: "100px" }} />
              </div>
              <p className="m-0">{this.state.body}</p>
            </Dialog>
          </div>
        ) : null}
      </>
    );
  };
  render() {
    const footerContent = () => {
      return (
        <div>
          <Button
            label="No"
            icon="pi pi-times"
            onClick={() => this.setState({ callToAction: false })}
            className="p-button-text"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            onClick={this.handleYesClick}
            autoFocus
          />
        </div>
      );
    };
    const footerContentPremium = () => {
      // width < 500 ?
      return (
        <div>
          {/* {true ? (
            <a href="tel:5555555555">
              <Button
                label="Call"
                icon="pi pi-phone"
                className="p-button-text"
              />
            </a>
          ) : null} */}
          <a href="mailto:qrhome@qrhome.xyz">
            <Button
              label="Email"
              icon="pi pi-envelope"
              className="p-button-text"
            />
          </a>
        </div>
      );
    };

    return (
      <>
        <this.CustomerService
          footerContentPremium={footerContentPremium}
          header={this.state.header}
        />
        <this.CallToAction footerContent={footerContent} />
      </>
    );
  }
}
