import { db } from "../firebase";
import getStripe from "./initializeStripe";
import LoadingUtil from "../components/LoadingDialog/LoadingUtil";
export async function createCheckoutSession(uid) {
  console.log(`createCheckoutSession:uid:${uid}`)
  // Create a new checkout session in the subollection inside this users document
  const checkoutSessionRef = await db.collection("admin")
    .doc(uid)
    .collection("checkout_sessions")
    .add({
      // replace the price_XXX value with the correct value from your product in stripe.
      price: "price_1NoZxpDgVKrv0qMb0EWpg98o",
      success_url: window.location.origin + '/dashboard',
      cancel_url: window.location.origin + '/dashboard',
      allow_promotion_codes: true 
    });

  // Wait for the CheckoutSession to get attached by the extension
  checkoutSessionRef.onSnapshot(async (snap) => {
    const { sessionId } = snap.data();
    if (sessionId) {
      // We have a session, let's redirect to Checkout
      // Init Stripe
      const stripe = await getStripe();
      stripe.redirectToCheckout({ sessionId });
      LoadingUtil.close()

    }
  });
}
export async function checkoutPlan(uid,plan) {
  console.log(`createCheckoutSession:uid:${uid}`)
  // Create a new checkout session in the subollection inside this users document
  const checkoutSessionRef = await db.collection("admin")
    .doc(uid)
    .collection("checkout_sessions")
    .add({
      // replace the price_XXX value with the correct value from your product in stripe.
      price: plan,
      success_url: window.location.origin,
      cancel_url: window.location.origin,
      allow_promotion_codes: true,
    });

  console.log("checkoutPlan:checkoutSessionRef", checkoutSessionRef)

  // Wait for the CheckoutSession to get attached by the extension
  checkoutSessionRef.onSnapshot(async (snap) => {
    const { sessionId } = snap.data();
    console.log("checkoutPlan:checkoutSessionRef:data", snap.data())
    if (sessionId) {
      // We have a session, let's redirect to Checkout
      // Init Stripe
      const stripe = await getStripe();
      stripe.redirectToCheckout({ sessionId});
      LoadingUtil.close()

    }
  });
}
