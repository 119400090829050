import { error } from "console";
import { COLLECTION } from "../constants/Text";
import { db } from "../firebase";
import Route from "../screens/Route";

// qrhome coupon and affiliate pricing plans
export async function updateOrg(orgId:string,value:any){
    console.log('updateOrgTags:orgId',orgId)
    try {
        const orgRef = await db.collection(COLLECTION.ORG).doc(orgId)
        orgRef.update(value)
    } catch(e) {
        console.log('updateOrg error', e)
        console.log('updateOrg catch value', value)
    }
  }