export const style = {
    cardContainer:{ textAlign: "start", margin: "0px 80px" ,marginTop:-30},
    formCard:{
      position: "relative",
      width:'100%',
      maxWidth: '950px',
      
      margin: '0 auto',
    },
    headerIcon:{
       position: "absolute", top: "-31px", left: "25px" 
    },
    cardHeader:{
      marginLeft:-10
    },
    cardInput:{
      width:'95%'
    },
    cardSubBody:{},
    cardBody:{ textAlign: "start"}
  }