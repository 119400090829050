import { useState } from "react";

import Navbar from "../../components/Nav/Navbar";
import { useHistory } from "react-router-dom";
import PanelSingleCard from "../../components/Panels/PanelSingleCard";
import DetailsCard from "../../components/Cards/DetailsCard";
import { useAuth } from "../../contexts/AuthContext";
import { Button } from "primereact/button";
import { addNewCampaign } from "../../data/CampaignService";
import { RegExpChecks } from "../../utils/RegEx";
import {screenStyles} from '../ScreenStyles'
import { useWindowSize } from "@react-hook/window-size";

export default function NewQRFormScreen() {
  const { currentUser, campaigns, setCampaigns,user } = useAuth();

  const initial: any = {
    title: "",
    url: "",
    description: "",
    // allowedAffiliateIds: [],
    clicks: 0,
    owner: currentUser.uid,
    id:null,
    times:[],
    link:'',
    QR:'',
    qrFGColor:'#000000',
    qrBGColor:'#ffffff',
    facebookId:'',
    googleId:'',
    orgId:user.orgId
  };
  const [campaign, updateCampaign] = useState(initial);
  const [canSubmit,updateCanSubmit] =  useState(true)

  console.log('NewQRFormScreen:context:campaigns:', campaigns)
  console.log('NewQRFormScreen:state:campaign:', campaign)
  const campaignUpdates = (value) => {
    console.log("campaignUpdates value", value);
    console.log("campaignUpdates campaign", campaign);
    let newValue = { ...campaign, ...value };
    console.log("campaignUpdates newValue", newValue);
    updateCampaign(newValue);
    updateCanSubmit(disableSubmit(newValue))
  };

  const disableSubmit=(value:any)=>{
    console.log(`disableSubmit:value`,value)
    console.log(`RegExpChecks.isValidUrl(value.url)`,RegExpChecks.isValidUrl(value.url))
    return !value.description || !value.title || !value.url 
  }
  const history = useHistory();

  return (
    <div
      style={{
        flex: 1,
        paddingTop: "80px",
        backgroundColor: "#EFEFEF",
      }}
    >
      <Navbar
        leftClick={() => {
          console.log("hello");
          history.goBack();
        }}
        pageTitle={"New QR"}
      />
      <div style = {screenStyles.body}>

     
      <PanelSingleCard
        update={campaignUpdates}
        state={campaign}
        component={DetailsCard}
      />

      
        <div style={buttonContainer}>
          <div style={{ width:'100%', maxWidth:'950px',  display: 'flex', justifyContent: 'space-between'}}>
            <Button
              onClick={() => history.goBack()}
              label="Cancel"
              className="p-button-danger"
              style={buttonStyles}
              
            />
            <Button
              style={buttonStyles}
              label="Submit"
              disabled={canSubmit}
              onClick={() => {
                console.log("NewCampaignFormScreen:campaign", campaign);
                addNewCampaign(campaign, currentUser.uid).then((campaign) => {
                  let copy = [...campaigns]
                  console.log("new campaign",campaign)
                  copy.push(campaign);
                  setCampaigns(copy)
                  history.goBack();
                });
              }}
            />
        </div>
      </div>
       </div>

    

      <div style={{ padding: "200px" }}></div>
    </div>
  );
}
const buttonStyles = {
  margin:'0%',
  marginTop:20,
  width:'40%'

};

const buttonContainer = {
  marginLeft:'5%',
  marginRight:'5%',
  maxWidth:'1250px',
  display: 'flex',
  justifyContent: 'center',
};