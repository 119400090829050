const DEFAULT_USERNAME = "Click to add a username";
const DEFAULT_BIO = "Click to add a bio";
const TEXT = {
    DEFAULT_BIO,
    DEFAULT_USERNAME,
    PROFILE_SCREEN_HEADLINE: 'Shop Online, Fund Your ❤️ Creators',
    PROFILE_SCREEN_SUBHEADING: '',
    LANDING_SCREEN_HEADLINE: '',
    NO_QRS_MESSAGE:'You currently have no QRs, Get Started Today!',
    NONE_ACTIVE_ACCOUNT:'Your account is not active.',
    PAYWALL_HEADER:"Purchase",
    RENEW_HEADER:"Renew",
    RENEW_BODY_FREE_TRIAL:"Recover your account today! The real estate market never sleeps, and neither should your marketing efforts.",
    RENEW_SUBHEADER_FREE_TRIAL: "Pickup where you left off",

    PAYWALL_BODY_FREE_TRIAL:"Get Started Now and Enjoy 30 Days FREE. Cancel Anytime with No Fuss. You'll Receive 15 Trackable, Resettable, Dynamic QRs for Your Business.",
    PAYWALL_SUBHEADER_FREE_TRIAL: "Unlock the Power of qrhome Today!",
    QR_LIMIT_BODY:" You have hit your QR limit. Contact us for a custom plan to fit your needs.",
    QR_LIMIT_MEMBERS_BODY:"You have reached the limit of QRs for your account. For assistance or questions reachout to your administrator",
    QR_LIMIT_HEADER:"QR Limit",
    ACCOUNT_RESTRICTION:"Admin Account",
    ADMIN_ACCOUNT:"This account is for administrative purposes only. If you need to create QRs, use a member account.",



}
export const COLLECTION = {
    ADMIN : "admin",
    CAMPAIGNS:"campaigns",
    AFFILIATES:"affiliates",
    MAIL:"mail",
    ORG:"org"

}
export default TEXT;