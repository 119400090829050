import React, {  useRef,useState } from 'react';
import {Navbar} from '@pkwy/react'
import "firebase/auth";
import {useAuth} from '../contexts/AuthContext';
import {Link,useHistory} from 'react-router-dom';
import { Toast } from 'primereact/toast';
import Logo from '../components/Logo'

export default function AccountRecovery () {
    const emailRef = useRef();
  
    const {resetPassword} = useAuth();
    const [error,setError] = useState('');
    const [loading,setLoading] = useState(false);
    const toast = useRef(null);
    const history = useHistory();
    const showError = () => {
        toast.current.show({severity:'error', summary: 'Failed to reset password',  life: 9000});
    }
    const showSuccess = () => {
        toast.current.show({severity:'success', summary: 'Success', detail:'Check your email for instructions', life: 9000});
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(`AccountRecovery.handleSubmit ${emailRef.current.value}`)
        
        try{
           setLoading(true)
           await resetPassword(emailRef.current.value);
           showSuccess();
           
        //    history.push("/profile")
        }
        catch (e){
            console.log(`bug ----------------- ${e}`)
            showError();
        }
        setLoading(false)
        
    }
  return (
   <div>
       <Toast ref={toast} position="bottom-right" />
    <body data-spy="scroll" data-target=".fixed-top">
    <Navbar LogoComp={Logo} bgColor="#5f63ba" handleLogoClick={()=>{history.push('/')}} handleSignUpClick={()=>{history.push('/signup');}} handleSignInClick={()=>{history.push('/signin');}}/>
    <header id="header" className="ex-2-header" style={{padding:"1rem"}}>
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1>Account Recovery</h1>
                   <p>What email do you use to login?</p> 
                    {/* <!-- Sign Up Form --> */}
                    <div className="form-container">
                        <form onSubmit={handleSubmit}>
                            <div id="logInForm" data-toggle="validator" data-focus="false">
                                <div className="form-group">
                                    <input type="email"  ref={emailRef} className="form-control-input notEmpty" id="lemail" required/>
                                    {/* <label className="label-control" for="lemail">Email</label> */}
                                    <label className="label-control" >Email</label>
                                    <div className="help-block with-errors"></div>
                                </div>
                            
                                <div className="form-group">
                                    <button disabled={loading} type="submit" className="form-control-submit-button" id="login-button">Send Password Reset Email</button>
                                </div>
                              
                            </div>
                        </form>
                    </div> 
                    {/* <!-- end of sign up form --> */}
                    
                    
                </div> 
            </div> 
        </div> 
    </header>
   

    
    	
    {/*<!-- jQuery for Bootstrap's JavaScript plugins -->*/}
    <script src="js/jquery.min.js"></script> 
    {/* <!-- Popper tooltip library for Bootstrap --> */}
    <script src="js/popper.min.js"></script> 
    {/* <!-- Bootstrap framework --> */}
    <script src="js/bootstrap.min.js"></script> 
    {/* <!-- jQuery Easing for smooth scrolling between anchors --> */}
    <script src="js/jquery.easing.min.js"></script> 
    {/* <!-- Swiper for image and text sliders --> */}
    <script src="js/swiper.min.js"></script> 
    {/* <!-- Magnific Popup for lightboxes --> */}
    <script src="js/jquery.magnific-popup.js"></script> 
    {/* <!-- Validator.js - Bootstrap plugin that validates forms --> */}
    <script src="js/validator.min.js"></script> 

    {/* <!-- Firebase App (the core Firebase SDK) is always required and must be listed first --> */}
    <script src="https://www.gstatic.com/firebasejs/7.17.2/firebase-app.js"></script>

    {/* <!-- If you enabled Analytics in your project, add the Firebase SDK for Analytics --> */}
    <script src="https://www.gstatic.com/firebasejs/7.17.2/firebase-analytics.js"></script>

    {/* <!-- Add Firebase products that you want to use --> */}
    <script src="https://www.gstatic.com/firebasejs/7.17.2/firebase-auth.js"></script>
    

    {/* <!-- Custom scripts --> */}
    <script src="js/scripts.js"></script> 
  </body>

                
</div>
        )
    
}
