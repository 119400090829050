import React, { useEffect } from 'react';

function DataRenderer({ component: Component, data }) {
  useEffect(()=>{
  },[data])
  console.log('DataRenderer:data', JSON.stringify(data))
    return (
      <div>
        {data.map((item, index) => (
          <div key={item.id} style = {{marginBottom:'20px'}}>
            <Component {...item} index={index} />
          </div>
        ))}
      </div>
    );
  }
  
  export default DataRenderer;
