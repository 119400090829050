import { Card } from "primereact/card";
import { useHistory } from 'react-router-dom';
import ToastUtil from "../ToastUtil/ToastUtil";
import "./CampaignCard.css";
import { BsClipboard } from "react-icons/bs";
import {getAffiliatedUrl} from '../../utils/CampaignUtils'
import { useAuth } from "../../contexts/AuthContext";
import DialogModalUtil from "../DialogModal/DialogModalUtil";
import TEXT from '../../constants/Text';
import { createCheckoutSession } from "../../stripe/createCheckoutSession";
import { ROLES } from "../../constants/Roles";
function abbreviateNumber(value) {
  var newValue = value;
  if (value >= 1000) {
      var suffixes = ["", "K", "M", "B","T"];
      var suffixNum = Math.floor( (""+value).length/3 );
      var shortValue = '';
      for (var precision = 2; precision >= 1; precision--) {
          shortValue = parseFloat( (suffixNum !== 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
          var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
          if (dotLessShortValue.length <= 2) { break; }
      }
      if (shortValue % 1 !== 0)  shortValue = shortValue.toFixed(1);
      newValue = shortValue+suffixes[suffixNum];
  }
  return newValue;
}


export default function CampaignCard(props) {
  const history = useHistory();
  const { user,activeSubscription, campaigns } = useAuth();
  const createSession = () =>{
    createCheckoutSession(user.uid);
  }
  console.log('CampaignCard:props',props)
  return (
    <Card style={{cursor:'pointer'}} onClick={()=>{
      if(campaigns.length === 1){
        history.push('./manageCampaign', props)
      }else if(activeSubscription || user.dev){
        history.push('./manageCampaign', props)
      } else if(user.orgId && user.role === ROLES.MEMBER){
        history.push('./manageCampaign', props)
      }
      else {
        DialogModalUtil.showCallToAction(TEXT.RENEW_HEADER,TEXT.RENEW_BODY_FREE_TRIAL,createSession,TEXT.RENEW_SUBHEADER_FREE_TRIAL);
      }
      
      }}>
      <div style={{ display: "flex" ,marginTop:-40}}>
        <div
          className="campaign-card-left"
          style={{ flex: "1 1 0"}}
        >
          <h3>{props.title}</h3>
          <div>
            <span> </span>
            <text> {
            props.description.length < 100 ? props.description 
            : props.description.substring(0, 100) + '...'}</text>
          </div>
        </div>
        <div className="campaign-card-right" style={{ display: "flex", alignItems: "center", flex: "1.75 1 0" }}>
 
          <div style={{ flex: "1 1 0",textAlign:'center' }}></div>
          <div style={{ flex: "1 1 0",textAlign:'center', }}>
            <h5>Impressions</h5>
            <text style={{textAlign:'center'}}>{abbreviateNumber(props.clicks)}</text>
          </div>
          <div style={{ flex: "0 0 0", marginTop:40}}>
         
            <div className="clipboard" onClick={(e)=>{
               e.stopPropagation(); 
              ToastUtil.showMessage('success','copied to clipboard')
              navigator.clipboard.writeText(getAffiliatedUrl(props.id,props.link));
            }}>
              <BsClipboard size = {15} style={{ color: "#6366f1" }} />
            </div>
            
          </div>

        </div>
      </div>
    </Card>
  );
}
