export const screenStyles = {
    body:{
        // display: 'flex',
        // flexDirection: 'column',
        alignItems: 'center',
        margin: '0 auto',
        maxWidth: '1250px',
        
        // padding: '0 20px',
    }
}
/**
 *   display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 800px; 
  width: 100%;
 */