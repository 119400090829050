import { useEffect, useState} from 'react';
import { Button } from "primereact/button";
import { InputText } from 'primereact/inputtext';
import { Card } from "primereact/card";
import { useAuth } from '../../contexts/AuthContext';
import 'primeicons/primeicons.css';

const styles = {
  title: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "5px",
    marginTop:-15,
    marginRight:'5%',
    fontSize: "1.5rem",
    fontWeight: "700"
  }
}

export default function BillingCard(props){
  const {user} = useAuth();

    useEffect(()=>{
      console.log('BillingCard:user',JSON.stringify(user));

    })
    console.log('BillingCard:user',user);
    return(
      <Card style={{wordWrap: 'break-word'}}>
        <div style={styles.title}>
          Enterprise Plan
        </div>
         
        <div>Your current bill is <span style={{ fontWeight:'600'}}>{props.bill}</span> </div>
        <div >You're next billing cycle is <span style={{ fontWeight:'600'}}>{props.nextBillingDate}</span></div>
         
      </Card>
    )
  }
  