 class  DialogModalUtil {
  public dialog: any;
  public static instance: DialogModalUtil;
     static dialog: any;

  constructor() {}
  public static getInstance() {
    if (!this.instance) {
      this.instance = new DialogModalUtil();
    }

    return this.instance;
  }
  public setToast(ref) {
    this.dialog = ref;
  }
  // Icon of call service
  // Call Button
  public showCustomerServiceRequired = (header:any, description:any) => {
    this.dialog.current.showCustomerServiceRequired(header,description);
  };
  public showCallToAction = (header:any, description:any,action:any,subheading?:string) => {
    this.dialog.current.showCallToAction(header,description,action,subheading);
  };
  public showAccountRestriction = (header:any, description:any) => {
    this.dialog.current.showAccountRestriction(header,description);
  };
}
export default DialogModalUtil.getInstance();
