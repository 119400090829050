import { useRef, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import EditTags from "./EditTags";


export default function EditModal(props) {
  const [title, setTitle] = useState(props.state.title);
  const [url, setUrl] = useState(props.state.url);

  const [description, setDescription] = useState(props.state.description);
  const [googleId, setGoogleId] = useState(props.state.googleId);
  const [facebookId, setFacebookId] = useState(props.state.facebookId);
  const [status] = useState(props.state.status);

  const toast = useRef(null);


  const handleSave = () => {
    console.log("handle save");
    props.updateState({title:title,description:description,status:status,url:url, googleId:googleId, facebookId:facebookId})
  };

  console.log("Edit modal render method running");
  return (
    <Dialog
      header="Edit Details"
      visible={props.visible}
      style={{ textAlign: "left",display:'flex',marginLeft:'5%',marginRight:'5%',width:'80%' }}
      onHide={() => props.setVisible(false)}
    >
      <div style={{ marginLeft: "5%",marginRight: "5%", fontSize: "25px", fontWeight: "700" }}>
        <div style = {{}}>Title</div>
        <InputText  style = {{width:'100%'}}value={title} onChange={(e) => setTitle(e.target.value)} />
        <div style = {{marginTop:10}}>Description</div>
        <InputTextarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          rows={5}
          style={{width:'100%'}}
        />
        <div style = {{marginTop:10,marginBottom:2}} >Url</div>
        <InputText style = {{width:'100%'}} value={url} onChange={(e) => setUrl(e.target.value)} />
        <EditTags setFacebookId={setFacebookId} facebookId={facebookId} setGoogleId={setGoogleId} googleId={googleId}/>
        {/* <div style = {{marginTop:10,marginBottom:2}}>Google Tag</div>
        <InputText style = {{width:'100%'}} value={googleId} onChange={(e) => setGoogleId(e.target.value)} />
        <div style = {{marginTop:10,marginBottom:2}}>Facebook Tag</div>
        <InputText style = {{width:'100%'}} value={facebookId} onChange={(e) => setFacebookId(e.target.value)} /> */}
        {/* <div style = {{marginTop:10}} >Attachments</div> */}
        <Toast ref={toast}></Toast>
        <br />
        <Button label="Save"  style={{display: "block", margin: '0 auto',width:'95%',marginTop:40}} onClick={handleSave} />
        <br />
      </div>
    </Dialog>
  );
}
