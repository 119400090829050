import { useState } from "react";
import Logo from "../Logo";
import { useWindowSize } from "@react-hook/window-size";
import NavMenu from "./RightOptionsMenu";
import Hamburger from "hamburger-react";
import OutsideClickHandler from "react-outside-click-handler";


var clickedOutside = false;
function Navbar(props) {
  const [width, height] = useWindowSize();
  const [isOpen, setOpen] = useState(false);

  console.log("Navbar:props", props);
  return (
    <>
      <div style={styles.navContainer}>
        <div onClick={props.leftClick} style={styles.leftContainer}>
          <Logo Dark withOutText={width < 450 ? true : false} />
          <div style={styles.verticalBorder} />

          <text style={styles.pageTitle}>{props.pageTitle}</text>
        </div>
        <div style={styles.rightContainer}>
          <Hamburger
            toggled={isOpen}
            toggle={() => {
              console.log("called Hamburger toggle", isOpen, clickedOutside);
              if (clickedOutside) {
                clickedOutside = false;
              } else {
                setOpen(!isOpen);
              }
            }}
          />
        </div>
      </div>
      <OutsideClickHandler
        onOutsideClick={() => {
          console.log("called onOutsideClick");
          if (isOpen) {
            clickedOutside = true;
            setOpen(false);
          } else {
            // clickedOutside = false;
          }
        }}
        onFocus={() => {
          console.log("Focused");
        }}
      >
        <NavMenu
          visible={isOpen}
          onBlur={() => {
            console.log("OnBlur");
          }}
        />
      </OutsideClickHandler>
    </>
  );
}
const styles = {
  navContainer: {
    backgroundColor: "#EFEFEF",
    width: "100%",
    left: "0px",
    top: "0px",
    position: "absolute",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    display: "flex",
    justifyContent: "space-between",
  },
  leftContainer: {
    marginLeft: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logoText: {
    fontFamily: "Pacifico",
    lineHeight: "70.24px",
    fontSize: "40px",
    marginRight: "10px",
    marginBottom: "10px",
    color: "black",
  },
  pageTitle: {
    marginLeft: "10px",
    marginRight: "10px",
    fontFamily: "Amiko",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "30px",
    lineHeight: "40px",
    color: "black",
  },
  verticalBorder: {
    height: "80%",
    borderRight: "2px solid black",
  },
  rightContainer: {
    marginRight: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rightNavText: {
    marginRight: "10px",
    fontFamily: "Amiko",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "40px",
  },
};
export default Navbar;
