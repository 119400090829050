import React from 'react'
import { Link } from "react-router-dom";
import Icon from "../assets/images/qrhomeicon.svg";
import IconDark from "../assets/images/qrhomeicon-dark.svg";

import PropTypes from "prop-types";

export default function Logo({Dark,withOutText}) {
  const styles = {
    ...(Dark && styleProps.dark),
  }
  
  return (
    <Link
    style={{ textDecoration: "none", fontSize: 30 }}
    className="navbar-brand logo-image"
    to="/"
  >
    <img src={Dark ? IconDark :Icon} width="60px" style={{marginTop:"-5px"}}/>
    {withOutText ? null:    <text className={"Logo"} style={styles}> qrhome</text> }

    
  </Link>
  )
}
const styleProps = {
  dark:{color:"#000000"}
}
Logo.propTypes = {
  Dark: PropTypes.bool,
};
Logo.defaultProps = {
  Dark: false,
 
};