import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import LoadingUtil from "./components/LoadingDialog/LoadingUtil";
import { PAYMENT_STATUS } from "./constants/PaymentStatus";
require("firebase/firestore");

const firebaseConfig = {
  apiKey: "AIzaSyApRG4CxGzIPICdsIgs3wKeTGCsg9ydy9Y",
  authDomain: "qrhome-38cd4.firebaseapp.com",
  projectId: "qrhome-38cd4",
  storageBucket: "qrhome-38cd4.appspot.com",
  messagingSenderId: "655418670949",
  appId: "1:655418670949:web:0dfe9a1bcbf7c422b5eb8c",
  measurementId: "G-B0EBV63MP9"
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();
var storage = firebase.storage();
var storageRef = storage.ref();
const db = firebase.firestore();
const auth = firebase.auth();
const increment = firebase.firestore.FieldValue.increment(1);
const decrement = firebase.firestore.FieldValue.increment(-1);

const isUserPremium = async () => {
  await firebase.auth().currentUser?.getIdToken(true);
  const decodedToken = await firebase.auth().currentUser?.getIdTokenResult();
  console.log("firebase:decodedToken:", decodedToken)
  return decodedToken?.claims?.stripeRole ? true : false;
}
const hasActiveSubscription = async (uid) => {
 console.log('hasActiveSubscription:uid',uid)
  var parentDocRef = db.collection("admin").doc(uid);
  var subCollectionRef = await parentDocRef.collection("subscriptions").get();
  var active = false
  subCollectionRef.forEach((doc) => {
    // Retrieve key-value pairs from each document
    var keyValuePairs = doc.data();
    console.log("Key-Value Pairs:", keyValuePairs);
    if (keyValuePairs.status === PAYMENT_STATUS.ACTIVE || keyValuePairs.status === PAYMENT_STATUS.TRAILING) {
        active = true;
    }
  });
  return active
}
const sendToCustomerPortal = async () => {
  console.log("sending user to customer portal!!")

  const functionRef = firebase
  .app()
  .functions('us-central1')
  .httpsCallable('ext-firestore-stripe-payments-createPortalLink');
 
  const { data } = await functionRef({
    returnUrl: window.location.origin,
    locale: "auto", // Optional, defaults to "auto"
  });

  window.location.assign(data.url);
  LoadingUtil.close();

}

export { db, auth, increment, decrement, storageRef, isUserPremium, sendToCustomerPortal,hasActiveSubscription};
