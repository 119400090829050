class  LoadingUtil {
    public loading: any;
    public static instance: LoadingUtil;
       static loading: any;
  
    constructor() {}
    public static getInstance() {
      if (!this.instance) {
        this.instance = new LoadingUtil();
      }
  
      return this.instance;
    }
    public show= () => {
      this.loading.current.show(true);
    };
    public close= () => {
        this.loading.current.show(false);
      };
  }
  export default LoadingUtil.getInstance();