import { Menu } from "primereact/menu";
import { useAuth } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { sendToCustomerPortal } from "../../firebase";
import { createCheckoutSession } from "../../stripe/createCheckoutSession";
import {isUserPremium} from '../../../src/firebase'
import LoadingUtil from "../LoadingDialog/LoadingUtil";
import { ROLES } from "../../constants/Roles";
import { sign } from "crypto";

/**
 * Component is currently used in dashboard
 *
 */
// #EFEFEF
const altStyle = {
  position: "fixed",
  top: "80px" /* Adjust this value as needed */,
  right: "20px" /* Adjust this value as needed */,
  zIndex: 999,
  backgroundColor: "#EFEFEF",
};
export default function NavMenu({ visible }) {
  const history = useHistory();
  const {activeSubscription} = useAuth();
  console.log('NavMenu:activeSubscription',activeSubscription)
  const billing =  {
    label: "Billing",
    icon:"pi pi-credit-card",
    command: async () => {
      LoadingUtil.show()
      sendToCustomerPortal()
    },
  }
  const plans = {
    label: "Plans",
    icon: "pi pi-external-link",
    command: async () => {
      history.push("/plans");
    },
  }
  const org = {
    label: "Manage",
    icon: "pi pi-external-link",
    command: async () => {
      history.push("/org");
    },
  }
  const passwordReset = {
    label: "Password Reset",
    icon: "pi pi-fw pi-pencil",
    command: () => {
      history.push("/account-recovery");
    },
  }
  const qrs = {
    label: "QRs",
    icon: "pi pi-qrcode",
    command: () => {
      history.push("/qrs");
    },
  }

  const seperator = { separator: true }
  const signout =  {
    label: "Sign Out",
    command: async () => {
      console.log("signout");
      await logout();
      history.push("/");
    },
  }


  const ROLES_OPTIONS = {
    ADMIN: [plans,passwordReset,seperator,signout],
    ADMIN_ACTIVE: [billing,passwordReset,seperator,signout],
    ORG_OWNER: [org,plans,passwordReset,seperator,signout],
    ORG_OWNER_ACTIVE: [org,billing,passwordReset,qrs,seperator,signout],
    ORG_MEMBER: [passwordReset,seperator,signout],
    ORG_ADMIN:[passwordReset,seperator,signout]
  }
 var items = ROLES_OPTIONS.ADMIN;
  const { logout,user } = useAuth();
  // individual agent account
  if(user.role == ROLES.ADMIN && user.orgId){
    items = ROLES_OPTIONS.ORG_ADMIN
  } else if(user.role == ROLES.OWNER && user.orgId && activeSubscription){
    items = ROLES_OPTIONS.ORG_OWNER_ACTIVE
  }
   else if(user.role == ROLES.OWNER && user.orgId && !activeSubscription){
    items = ROLES_OPTIONS.ORG_OWNER
  }
  else if( user.role == ROLES.MEMBER && user.orgId ){
    items = ROLES_OPTIONS.ORG_MEMBER
  } else if(user.role == ROLES.ADMIN && activeSubscription && !user.orgId){
    items = ROLES_OPTIONS.ADMIN_ACTIVE
  }

  if(!visible){
    return null
  }

  return (
    <>
  
        <div style={altStyle}>
          <Menu model={items} />
        </div>
    
    </>
  );
}
