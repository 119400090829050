import React, { useState, useEffect, useRef } from 'react';
import { Card } from "primereact/card";
import 'primeicons/primeicons.css';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { RadioButton } from 'primereact/radiobutton';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import LoadingUtil from "../../components/LoadingDialog/LoadingUtil";
import {useAuth} from '../../contexts/AuthContext';
import ToastUtil from "../../components/ToastUtil/ToastUtil";
import {BASE_URL} from '../../constants/routes';
import { getInvoice } from "../../data/PaymentService";

import { update } from '../../data/CampaignService';

const styles = {
    title: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "5px",
      marginTop:-15,
      marginRight:'5%',
      fontSize: "1.5rem",
      fontWeight: "700"
    },

  }
// var baseUrl = "https://us-central1-qrhome-38cd4.cloudfunctions.net/app/";
// var baseUrl = 'https://qrhome.xyz/manage/'

export default function MembersCard(props){

    let emptyProduct = {
        id: null,
        name: '',
        email: '',
        role: null,
    };

    const { currentUser,user,refreshAmountDue,org,setOrg } = useAuth();
    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [editing, setEditing] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

   
    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    };
    useEffect(async ()=>{
        console.log('MembersCard:useEffect',user.billingAccess)
        // TODO: optimization dont fetch if added a admin
        if(user.billingAccess){
            refreshAmountDue()
        }
    },[org.members.length])

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    };

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    };

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    };

    const saveProduct = async () => {
        console.log("MembersCard:SaveProduct:")
        setSubmitted(true);
        LoadingUtil.show()

        // const baseUrl = 'https://qrhome.xyz/manage/'
        // const baseUrl = 'https://us-central1-qrhome-38cd4.cloudfunctions.net/app'
        const endpoint = editing ?  '/update-user' : '/add-user';
        const url = BASE_URL + endpoint;


        if (product.name && product.email && product.role) {
            setProductDialog(false);

            let _products = [...org.members];
            let _product = { ...product };

            //post to server to handle member creation and billing logic
             currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
                // Send token to your backend via HTTPS
                console.log('MembersCard:idToken:',idToken)
                console.log('MembersCard: adding a new user:', product)
                
                fetch(url, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ idToken: idToken, user: product}),
                })
                .then(response => {
                    console.log("Token sent successfully:", response);
                    return response.json();
                })
                .then(responseJson =>{
                    console.log("responseJson", responseJson)

                    if(responseJson.severity == "success"){
                        console.log("success case!")
                        const index = findIndexById(responseJson.user.id);
                        _product.id = responseJson.user.id //updating user id since it is created via firebase

                        if(index == -1){
                            console.log("product not found, pusing it into array ")
                            _products.push(_product); //creating user
                        }else{
                            console.log("product found at index:", index)
                            _products[index] = _product; //updating user
                        }

                        console.log("products:", _products)
                        // setProducts(_products);
                        setOrg({...org,members:_products})

                        // props.updateOrg({...props.org, members : _products })
                        refreshAmountDue();
                        ToastUtil.showMessage(responseJson.severity, responseJson.code,  responseJson.message,  5000 ); 

                    }else{
                        ToastUtil.showMessage(responseJson.severity, responseJson.code,  responseJson.message,  10000 ); 
                    }
                    LoadingUtil.close()
                    setProduct(emptyProduct); //update local state
                })
                .catch(error => {
                console.error("Error sending token to backend:", error);
                ToastUtil.showMessage("error", "Error", error,  5000 );
                LoadingUtil.close()

                });
            }).catch(function(error) {
                LoadingUtil.close()
                ToastUtil.showMessage("error", "Error", error,  5000 );
                console.log('MembersCard:error:', error)
            });
              
        } else {
            LoadingUtil.close();
        }
        setEditing(false)
        
    };

    const editProduct = (product) => {
        setProduct({ ...product });
        setEditing(true);
        setProductDialog(true);

    };

    const confirmDeleteProduct = (product) => {
        setProduct(product);
        setDeleteProductDialog(true);
    };

    const deleteProduct = () => {
        LoadingUtil.show()
        console.log('deleting product')
        let _products = org.members.filter((val) => val.id !== product.id);

        try {
            //post to server to handle member creation and billing logic

            currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
            // Send token to your backend via HTTPS
            console.log('MembersCard:idToken:',idToken)
            console.log('MembersCard:new user email:', product.email )
            
            fetch(`${BASE_URL}/remove-user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ idToken: idToken, user: product}),
                })
                .then(response => {
                    // Handle the response from your backend
                    console.log("Token sent successfully:", response);
                    return response.json();
                })
                .then(responseJson => {
                    LoadingUtil.close()
                    if(responseJson.severity === "success"){

                        // setProducts(_products);
                        setOrg({...org,members:_products})

                        // props.updateOrg({...props.org, members : _products })
                        ToastUtil.showMessage(responseJson.severity, responseJson.code,  responseJson.message,  5000 ); 
                    }else{
                        ToastUtil.showMessage(responseJson.severity, responseJson.code,  responseJson.message,  10000 ); 
                    }
        
                })
                .catch(error => {
                // Handle errors in sending the token to your backend
                LoadingUtil.close()
                console.error("Error sending token to backend:", error);
                ToastUtil.showMessage("error", "Error", error,  5000 );
                });
            }).catch(function(error) {
            // Handle error
            LoadingUtil.close()
            console.log('MembersCard:error:', error)
            ToastUtil.showMessage("error", "Error", error,  5000 );
            });

        }catch(error){
            LoadingUtil.close()
            ToastUtil.showMessage('error', 'Error', error,  10000 );
        }

        setDeleteProductDialog(false);
        setProduct(emptyProduct);
        
    };

    const findIndexById = (id) => {
        let index = -1;

        for (let i = 0; i < org.members.length; i++) {
            if (org.members[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const importCSV = () =>{
        console.log("importing CSV")
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    };

    const deleteSelectedProducts = () => {
        let _products = org.members.filter((val) => !selectedProducts.includes(val));

        // setProducts(_products);
        setOrg({...org,members:_products})
        setDeleteProductsDialog(false);
        setSelectedProducts(null);
        ToastUtil.showMessage('success', 'Successful', 'Products Deleted', 3000 );
    };

    const onRoleChange = (e) => {
        let _product = { ...product };

        _product['role'] = e.value;
        setProduct(_product);
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };

        _product[`${name}`] = val;

        setProduct(_product);
    };


    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="New" icon="pi pi-plus" severity="success" onClick={openNew} />
                {/* <Button label="Delete" icon="pi pi-trash"  className="p-button-danger" severity="danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
            </div>
        );
    };

    const rightToolbarTemplate = () => {
        return <Button label="Import" icon="pi pi-download"  onClick={exportCSV} />;
    };


    const actionBodyTemplate = (rowData) => {
        console.log('rowData', rowData)
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" rounded  outlined className="mr-2" disabled={rowData.role === 'owner'} onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" rounded outlined className="p-button-danger" severity="danger" disabled={rowData.role === 'owner'} onClick={() => confirmDeleteProduct(rowData)} />
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Manage Users</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const productDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={saveProduct} />
        </React.Fragment>
    );
    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-danger" severity="danger" onClick={deleteProduct} />
        </React.Fragment>
    );
    const deleteProductsDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-danger" severity="danger" onClick={deleteSelectedProducts} />
        </React.Fragment>
    );

    return(
        
        <Card style={{wordWrap: 'break-word'}}>
          <div style={styles.title}>
            Members & Admin
          </div>
          <div style={{ marginBottom:'20px'}}>Members have the ability to create and manage QR codes. Admins can only manage members and does not affect your billing.</div>
        <div>
            <div className="card">
                <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>
                {/* <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar> */}

                <DataTable ref={dt} tableStyle={{padding:'100px'}} size="small" stripedRows value={org.members} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id"  paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products" globalFilter={globalFilter} header={header}>
                    {/* <Column selectionMode="multiple" exportable={false}></Column> */}
                    <Column field="email" header="Email" sortable style={{ minWidth: '16rem' }}></Column>
                    <Column field="name" header="Name" sortable style={{ minWidth: '16rem' }}></Column>
                    <Column field="role" header="Role" sortable style={{ minWidth: '10rem' }}></Column>
                 
                    <Column header="Manage" body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </div>

            <Dialog visible={productDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="User Details" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                {product.image && <img src={`https://primefaces.org/cdn/primereact/images/product/${product.image}`} alt={product.image} className="product-image block m-auto pb-3" />}
                <div className="field">
                    <label htmlFor="name" className="font-bold">
                        Name
                    </label>
                    <InputText id="name" value={product.name} disabled={editing} onChange={(e) => onInputChange(e, 'name')} required className={classNames({ 'p-invalid': submitted && !product.name })} />
                    {submitted && !product.name && <small className="p-error">Name is required.</small>}
                </div>

                <div className="field">
                    <label htmlFor="email" className="font-bold">
                        Email
                    </label>
                    <InputText id="email" value={product.email} disabled={editing} onChange={(e) => onInputChange(e, 'email')} required className={classNames({ 'p-invalid': submitted && !product.email })} />
                    {submitted && !product.email && <small className="p-error">Email is required.</small>}
                </div>

                <div className="field">
                    <label className="mb-3 font-bold">Role</label>
                    <div className="formgrid grid">
                        <div className="field-radiobutton col-6">
                            <RadioButton inputId="role1" name="role" value="member" required  onChange={onRoleChange} checked={product.role === 'member'} />
                            <label htmlFor="role1">member</label>
                        </div>
                        <div className="field-radiobutton col-6">
                            <RadioButton inputId="role2" name="role" value="admin" required  onChange={onRoleChange} checked={product.role === 'admin'} />
                            <label htmlFor="role2">admin</label>
                        </div>
                    </div>
                    {submitted && !product.role && <small className="p-error">Role is required.</small>}
                </div>

            </Dialog>

            <Dialog visible={deleteProductDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {product && (
                        <span>
                            Are you sure you want to delete <b>{product.name}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={deleteProductsDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {product && <span>Are you sure you want to delete the selected products?</span>}
                </div>
            </Dialog>
        </div>
        </Card>
   
    )
  }
  
  