import { Component } from "react";
import Lottie from "react-lottie";
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer'
import "../css/AboutScreenStyles.css";
import Marker from "../lotties/Marker.json";
import "primeflex/primeflex.css";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Marker,
  rendererSettings: {
    // preserveAspectRatio: "xMidYMid slice",
  },
};

export default class TermsOfServiceScreen extends Component {
  render() {
    const styles = {
      title: {
        marginTop:'20px',
        fontSize:'16px',
        fontWeight:600,
      },
    };


    return (
      <>
       <Navbar bgColor="#282b30"/>
        <div style={{ backgroundColor:"#282b30", display:'flex', flexDirection:'column', margin:'0% 10% 5% 10%',  color: '#d9e3ea', }}>
          
          <div style={{display:'flex', width:'100%', alignItems:'center', justifyContent:'center'}} >
            <h1 style={{}}>Terms Of Service</h1>
          </div>

          <div style={styles.title}>1. US and Our Service</div>
          <div>Qrhome.xyz is a service of Saguaro Tech Inc, a limited liability corporation. qrome.xyz the service allows businesses of all sizes to make dynamic QR codes for marketing purposes. We hope it brings you insight into engagement, saves you money with reduced reprinting and helps your customers find you, and your services.</div>
          <div style={styles.title}>2. Terms of Service</div>
          <div>These terms and conditions (“Agreement”) set forth the general terms and conditions of your use of the qrome.xyz website (“Website” or “Service”) and any of its related products and services (collectively, “Services”). This Agreement is legally binding between you (“User”, “you” or “your”) and this Website operator (“Operator”,“Saguaro”, “Saguaro Tech Inc” , "qrhome", "qrhome.xyz", "qrhome team", "qrhome.xyz team", “we”, “us”, "team", or “our”). If you are entering into this agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such an entity to this agreement, in which case the terms “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this agreement, you must not accept this agreement and may not access and use the Website and Services. By accessing and using the Website and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. You acknowledge that this Agreement is a contract between you and the Operator, even though it is electronic and is not physically signed by you, and it governs your use of the Website and Services. Saguaro may in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.</div>
          <div style={styles.title}>3. Using our service</div>
          <div>Although the Website and Services may link to other resources, we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked resource, unless specifically stated herein. We are not responsible for examining or evaluating, and we do not warrant the offerings of any businesses or individuals or the content of their resources. We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties. You should carefully review the legal statements and other conditions of use of any resource which you access through a link on the Website. Your linking to any other off-site resources is at your own risk.</div>
          <div style={styles.title}>4. Data</div>
          <div>Following termination or deactivation of your account, Saguaro may retain your User Content. In the ordinary course of our business, if you elect to permanently close your account, you may not be able to access information provided or generated by you once your account is terminated.</div>
          <div style={styles.title}>5. Indemnity</div>
          <div>You agree to indemnify and hold harmless Saguaro and our respective officers, directors, employees, affiliates and agents from and against any claims, suits, proceedings, disputes, demands, liabilities, damages, losses, costs and expenses, including, without limitation, reasonable legal and accounting fees (including costs of defense of claims, suits or proceedings brought by third parties), in any way.</div>
          <div style={styles.title}>6. Disclaimers</div>
          <div>Saguaro is not responsible for any content, code or any other imprecision.</div>
          <div>Saguaro does not provide warranties or guarantees. In no event shall Saguaro be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service. The Company reserves the right to make additions, deletions, or modifications to the contents on the Service at any time without prior notice.</div>
          <div>The Service and its contents are provided “as is” and “as available” without any warranty or representations of any kind, whether express or implied. Saguaro is a distributor and not a publisher of the content supplied by third parties; as such, Saguaro exercises no editorial control over such content and makes no warranty or representation as to the accuracy, reliability or currency of any information, content, service or merchandise provided through or accessible via the Saguaro Service. Without limiting the foregoing, Saguaro specifically disclaims all warranties and representations in any content transmitted on or in connection with the Saguaro Service or on sites that may appear as links on the Saguaro Service, or in the products provided as a part of, or otherwise in connection with, the Saguaro Service, including without limitation any warranties of merchantability, fitness for a particular purpose or non-infringement of third party rights. No oral advice or written information given by Saguaro or any of its affiliates, employees, officers, directors, agents, or the like will create a warranty. Price and availability information is subject to change without notice. Without limiting the foregoing, Saguaro does not warrant that the Service will be uninterrupted, uncorrupted, timely, or error-free.</div>
          <div style={styles.title} >7. Security</div>
          <div>We care about the security of our users. While we work to protect the security of your content and account, Saguaro cannot guarantee that unauthorized third parties will not be able to defeat our security measures. We ask that you keep your password secure. Please notify us immediately of any compromise or unauthorized use of your account. For accounts created on behalf of a company, organization or other entity, you are responsible for ensuring that only authorized individuals have access to the account.</div>
          <div style={styles.title} >8. Governing law and jurisdiction</div>
          <div>These Terms shall be governed by the laws of the State of Texas, without respect to its conflict of laws principles. The exclusive place of jurisdiction for all disputes arising from or in connection with this agreement is Dallas County, Texas or the United States District Court for Northern District of Texas, and our dispute will be determined under Texas law.</div>
          <div style={styles.title} >9. Changes and amendments</div>
          <div>We reserve the right to modify this Agreement or its terms related to the Website and Services at any time at our discretion. When we do, we will revise the updated date at the bottom of this page, and may send you an email to notify you. We may also provide notice to you in other ways at our discretion, such as through the contact information you have provided. An updated version of this Agreement will be effective immediately upon the posting of the revised Agreement unless otherwise specified. Your continued use of the Website and Services after the effective date of the revised Agreement (or such other act specified at that time) will constitute your consent to those changes.</div>
          <div style={styles.title} >10. General Terms</div>
          <div>These Terms, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by Saguaro without restriction. If any provision of these Terms is deemed invalid, that provision will be limited or eliminated to the minimum extent necessary and the remaining provisions of these Terms will remain in full force and effect.  No waiver of any term of these Terms shall be deemed a further or continuing waiver of such term or any other term, and Saguaro’s failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision.</div>
          <div style={styles.title}>Contacting us</div>
          <div>If you have any questions, concerns, or complaints regarding this Agreement, we encourage you to contact us using the details below:</div>
          <div><a href="mailto:qrhome@qrhome.xyz" style={{color:'#d9e3ea'}}>qrhome@qrhome.xyz</a></div>
        </div>
        <Footer />
      </>
    );
  }
}
